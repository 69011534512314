<div id="feedbacktab"><a href="#" (click)="handleOpenFeedbackForm()">Feedback</a></div>

<div id="loadingContent" *ngIf="loading">
    <div class="spinner">
        <div class="dot1"></div>
        <div class="dot2"></div>
    </div>
</div>

<div class="row force-fit" *ngIf="!loading">    
    <div class="layout">

        <div class="menuBar">
            <alii-web-protocol-menubar
                *ngIf="protocol"
                (eventBus)="handleEventBus($event)"
                [isEditAble]="isEditAble"
                [protocol]="protocol"
                [views]="views"
                style="width: 100%"
                [version]="version"
                >
            </alii-web-protocol-menubar>
        </div>


        <!--    <div class="layout__page">-->
        <alii-web-protocol-sidebar
            *ngIf="protocol"
            (eventBus)="handleEventBus($event)"
            [isEditAble]="isEditAble"
            [protocol]="protocol"
            [versionId]="versionId"
            [version]="version"
            [currentVersion]="currentVersion"
            [draftVersion]="draftVersion"
            [deprecatedVersions]="deprecatedVersions"
            [storedDraftVersions]="storedDraftVersions"
            [scrollParagraphId]="scrollParagraphId"
            [pane]="pane"
            [ngClass]="{ waiting: loading }"
        >
        </alii-web-protocol-sidebar>

        <main id="content" class="layout__content margin-Top" *ngIf="pane === 'content'" [ngClass]="{ waiting: loading }">
            <div class="page">
                <div class="loading-model-msg" *ngIf="loadingModel && showMessageIsLoading">Model is being
                    calculated
                </div>

                <div #scroll class="page__content py-4" id="protocol-detail">
                    

                    <alii-web-protocol-info
                        *ngIf="['Preview', 'Draft'].includes(version) && currentVersion !== 'flowchart'"
                        [protocol]="protocol"
                        [version]="version"
                        (eventBus)="handleEventBus($event)"
                    >
                    </alii-web-protocol-info>

                    <ng-container *ngIf="!loading">
                        <div *ngIf="protocol.banner && showBanner">
                            <ngb-alert class="banner" (close)="closeBanner()">{{ protocol.banner }}</ngb-alert>
                        </div>
                        <div id="protocolContent" class="mainContentArea">
                            <div *ngIf="currentView == 'flowchart'" id="flowChart" class="container-fluid">
                                <flowchart-detail
                                    *ngIf="views.includes('flowchart')"
                                    [scheme]="protocol.flowchart?.scheme | asHtml"
                                    [flowchart]="protocol.flowchart"
                                    (eventBus)="handleEventBus($event)"
                                >
                                </flowchart-detail>
                            </div>

                            <div *ngIf="currentView == 'interactive' && protocol.flowchart.cards" class="container-fluid">
                                <dialogue-detail
                                    [cards]="protocol.flowchart.cards"
                                    [paragraphs]="protocol.paragraphs"
                                    (eventBus)="handleEventBus($event)"
                                >
                                </dialogue-detail>
                            </div>

                            <ng-container *ngIf="currentView == 'text' || currentView == 'populations'">
                                <alii-web-paragraph-detail
                                    *ngFor="let paragraph of protocol.paragraphs; trackBy: trackByFn; index as i"
                                    (eventBus)="handleEventBus($event)"
                                    [protocolId]="protocol.id"
                                    [parentId]="null"
                                    [index]="i"
                                    [populationId]="populationId"
                                    [paragraph]="paragraph"
                                    [isEditAble]="isEditAble"
                                    [paragraphSelectedId]="paragraphSelectedId"
                                    [modelFindings]="modelFindings"
                                    [modelOutcomes]="modelOutcomes"
                                    [modelTagList]="modelTagList"
                                    [modelMessages]="modelMessages"
                                    [viewByPopulation]="currentView == 'populations'"
                                    [gradeAssessment]="gradeAssessment"
                                    [columnDefs]="paragraph.columnDefs"
                                    [rowData]="paragraph.rowData"
                                    [populations]="populations"
                                    [sidebarCollapsed]="sidebarCollapsed"
                                    [hasNumberedIndex]="protocol.hasNumberedIndex"
                                    [version]="version"
                                >
                                </alii-web-paragraph-detail>


                                <alii-web-protocol-literature
                                    *ngIf="protocol.search_term"
                                    [protocol]="protocol"
                                    (eventBus)="handleEventBus($event)"
                                >
                                </alii-web-protocol-literature>

                                <alii-web-protocol-info
                                    *ngIf="!['Preview', 'Draft'].includes(version)"
                                    [protocol]="protocol"
                                    [version]="version"
                                    (eventBus)="handleEventBus($event)"
                                ></alii-web-protocol-info>

                                <alii-web-protocol-files
                                    *ngIf="protocol.files && protocol.files.length > 0"
                                    [files]="protocol.files"
                                >
                                </alii-web-protocol-files>
                            </ng-container>
                        </div>
                    </ng-container>


                </div>
            </div>

            <div class="page__corner -top -right" style="z-index: 400" id="savePatientButton" *ngIf="!!populationId">
                <div class="button-group">
                    <a
                        class="button"
                        (click)="handleOpenSavePatientLinkModal()"
                        [ngClass]="'-primary'"
                    >
                        <span class="button__icon"><span class="icon-alii-text"></span></span> Opslaan
                    </a>
                </div>
            </div>
        </main>


        <main id="content-settings" class="layout__content" *ngIf="pane === 'settings'">
            <alii-web-protocol-settings [protocol]="protocol"
                                        (eventBus)="handleEventBus($event)"></alii-web-protocol-settings>
        </main>
    </div>
    <!--</div>-->
</div>
