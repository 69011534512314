export const nl = {
    LOGIN: {
        TITLE: 'Inloggen met een Alii acount',
        USERNAME: 'Gebruikersnaam',
        PASSWORD: 'Wachtwoord',
        'STAY-LOGGED-IN': 'Blijf ingelogd',
        'FORGOT-PASSWORD': 'Wachtwoord vergeten?',
        REGISTER: 'Wil je je registreren?',
        SSO: 'Inloggen met instellingsaccount'
    },
    LOGOUT: {
        CONFIRM: {
            TITLE: 'Uitloggen',
            TEXT: 'Weet je zeker dat je wilt uitloggen?'
        }
    },
    LANGUAGE: {
        SWITCH: {
            EN: 'Omgeschakeld naar de Engelse taal',
            NL: 'Omgeschakeld naar de Nederlandse taal'
        }
    },
    REGISTER: {
        TITLE: 'Op deze pagina kunt u zich registreren voor het Alii Platform',
        FIRSTNAME: 'Voornaam',
        LASTNAME: 'Achternaam',
        EMAIL: 'Email adres',
        TELEPHONE: 'Telefoon nummer (optioneel)',
        INSTITUTION: 'Instelling',
        HOSPITAL: 'Ziekenhuis',
        INTEREST: 'Interesse',
        'GOTO-LOGIN': 'Ga naar login pagina',
        TERMS: 'Ik accepteer de algemene voorwaarden',
        'GOTO-TERMS': 'Ga naar algemene voorwaarden pagina',
        LOGIN: 'Inloggen'
    },
    FEEDBACK: {
        TITLE: 'Graag horen wij uw feedback',
        MESSAGE: 'Bericht',
        NAME: 'Naam',
        EMAIL: 'Email adres',
        THANKS: 'Bedankt voor uw bericht!'
    },
    DASHBOARD: {
        BANNER: {
            CLOSE: 'Bericht sluiten'
        },
        CHOOSETEAM: {
            INTRO: 'Kies een van de volgende dashboards om mee te beginnen. Dit kun je later altijd wijzigen.'
        },
        MANAGE: {
            TITLE: 'Dashboards beheren',
            EDIT_CATEGORY: 'Geselecteerde categorie bewerken',
            CREATE_CATEGORY: 'Nieuwe categorie aanmaken',
            DELETE_DASHBOARD: 'Dashboard verwijderen',
            SUBTITLE: {
                LIST: 'Lijst van beschikbare dashboards',
                UPDATE: 'Een bestaand dashboard bewerken',
                CREATE: 'Een nieuw dashboard creëren'
            },
            MAXLEN: 'Max. aantal karakters toegestaan',
            SELECT: 'Een dashboard selecteren',
            CREATED: {
                OK: 'Nieuw dashboard is succesvol aangemaakt',
                NOK: 'Kan geen dashboard aanmaken'
            },
            DELETE: {
                TITLE: 'Dashboard verwijderen',
                TEXT: 'Weet je zeker dat je dit dashboard wilt verwijderen?'
            },
            MANAGE: 'Beheer',
            EXPLAIN_EDIT_CATEGORIES: 'Dubbelklik op een categorie om het te verplaatsen naar de andere rij',
            IS_EXCLUSIVE_TO_TEAM: 'Is het Dashboard exclusief voor het huidige team?',
            SETTINGS: 'Instellingen',
            'SELECT-CREATE': 'Een nieuw dashboard creëren',
            ACTION: {
                LOAD: {
                    OK: 'Dashboard succesvol geladen',
                    NOK: 'Dashboard kan niet worden gevonden, graag een andere van de lijst selecteren'
                },
                CREATE: {
                    OK: 'Nieuw dashboard succesvol aangemaakt',
                    NOK: 'Nieuw dashboard kan niet worden aangemaakt'
                },
                UPDATE: {
                    OK: 'Dashboard succesvol bijgewerkt',
                    NOK: 'Dashboard kan noet worden bijgewerkt'
                },
                DELETE: {
                    OK: 'Dashboard succesvol verwijderd',
                    NOK: 'Dashboard kan niet worden verwijderd'
                }
            },
            'SUBITEM-LIST': {
                SELECTED: 'Categorieën op dashboard',
                AVAILABLE: 'Beschikbare categorieën'
            },
            TOOLTIP: {
                UPDATE: 'Wijzigingen van dit dashboard opslaan',
                RESET: 'Oorsprokelijke waarden van dit dashboard tergugzetten',
                DELETE: 'Dit dashboard verwijderen!',
                CREATE: 'Een nieuw categorie creëren',
                MANAGE: 'Ga naar de categorie-beheer pagina'
            }
        },
        ACTIONS: {
            SELECT: {
                TITLE: 'Dashboard',
                TOOLTIP: 'Een ander dashboard selecteren'
            },
            MANAGE: {
                TITLE: 'Beheer',
                'DOCUMENT-CREATE': 'Document aanmaken',
                'DOCUMENT-IMPORT': 'Document importeren',
                'DASHBOARDS-MANAGE': 'Dashboards',
                'DASHBOARD-EDIT': 'Huidig dashboard',
                'CATEGORIES-MANAGE': 'Categorieën'
            }
        },
        PATIENTS: {
            TITLE: 'Mijn patienten',
            'ADD-NEW-CASE': 'Casus toevoegen',
            'ADD-EXISTING-CASE': 'Bestaande casus toevoegen',
            DELETE: {
                TITLE: 'Verwijder casus',
                TEXT: 'Weet je zeker dat je deze casus wilt verwijderen?'
            }
        }
    },
    _CATEGORY: {
        MANAGE: {
            TITLE: 'Categorieën beheren',
            SUBTITLE: {
                LIST: 'Lijst van beschikbare categorieën',
                UPDATE: 'Een bestaande categorie bewerken',
                CREATE: 'Een nieuw categorie creëren'
            },
            MAXLEN: 'Max. aantal karakters toegestaan',
            SELECT: 'Een categorie selecteren',
            CREATED: {
                OK: 'Nieuwe categorie is succesvol aangemaakt',
                NOK: 'Kan geen categorie aanmaken'
            },
            DELETE: {
                TITLE: 'Categorie verwijderen',
                TEXT: 'Weet je zeker dat je deze categorie wilt verwijderen?'
            },
            MANAGE: 'Protocol tonen',
            'SELECT-CREATE': 'Een nieuwe categorie creëren',
            ACTION: {
                LOAD: {
                    OK: 'Categorie succesvol geladen',
                    NOK: 'Categorie kan niet worden gevonden, graag een andere van de lijst selecteren'
                },
                CREATE: {
                    OK: 'Nieuwe categorie succesvol aangemaakt',
                    NOK: 'Nieuwe categorie kan niet worden aangemaakt'
                },
                UPDATE: {
                    OK: 'Categorie succesvol bijgewerkt',
                    NOK: 'Categorie kan noet worden bijgewerkt'
                },
                DELETE: {
                    OK: 'Categorie succesvol verwijderd',
                    NOK: 'Categorie kan niet worden verwijderd'
                }
            },
            'SUBITEM-LIST': {
                SELECTED: 'Geselecteerde protocollen',
                AVAILABLE: 'Beschikbare protocollon'
            },
            TOOLTIP: {
                UPDATE: 'Wijzigingen van deze categorie opslaan',
                RESET: 'Oorsprokelijke waarden van deze categorie tergugzetten',
                DELETE: 'Deze categorie verwijderen!',
                MANAGE: 'Ga naar de geselecteerde protocol pagina',
                DASHBOARD: 'Ga naar de dashboard beheer pagina'
            },
            LISTITEM: 'Categorie',
            LISTITEMS: 'Categorieën',
            SUBITEM: 'Protocol',
            SUBITEMS: 'Protocollen'
        }
    },
    PROTOCOLS: {
        CREATE: {
            'PASTE-CONTENT': 'Inhoud plakken',
            HELP:
                // eslint-disable-next-line max-len
                'Met een bestaande document beginnen|Als je met een bestaande document wilt beginnen dan kan je de inhoud hieronder plakken en deze zal worden ingelezen als startpunt.'
        },
        IMPORT: {
            PLACEHOLDER: 'Document zoeken'
        },
        DELETE: {
            TITLE: 'Verwijder document',
            TEXT: 'Weet je zeker dat je deze document wilt verwijderen?'
        },
        MARGIN: {
            'OLDER-VERSIONS': 'Oudere versies',
            BUTTON: {
                'TEXT': 'Tekst',
                'FLOW': 'Flow',
                'INTERACTIVE': 'Kaarten',
                'CONTENT': 'Inhoud',
                'REVIEW-DRAFT': 'Draft reviewen',
                'REVIEW-LIT': 'Literatuur',
                SETTINGS: 'Instellingen',
                'CURRENT-VERSION': 'Huidige versie',
                DRAFT: 'Concept',
                'CURRENT-DRAFT-VERSION': 'Huidige conceptversie',
                'CREATE-DRAFT': 'Nieuw concept'
            }
        }
    },
    SETTINGS: {
        'TITLE': 'Titel',
        'LITERATURE': 'Literatuurbeoordeling',
        'FLOWCHART': 'Toon flowchart (indien aanwezig)',
        'INTERACTIVE': 'Toon kaarten',
        'ALLTHETEAMMEMBERS': 'Alle leden van mijn organisatie',
        'AUTHORSONLY': 'Alleen de auteurs',
        'TRUE': 'Ja',
        'FALSE': 'Nee',
        'OWNER': 'Beheerders:',
        'PROTOCOLREVIEWBOARD': 'Beoordelaars van dit document:',
        'TEAMREVIEWBOARD': 'Beoordelaars organisatiebreed:',
        'PROTOCOLSUBSCRIBERS': 'Geabonneerd op wijzigingen:',
        'AUTHORS': 'Medeauteurs:',
        'REMOVEAUTHOR': 'Verwijder',
        'SETOWNER': 'Maak beheerder',
        'SETREVIEWER': 'Maak beoordelaar',
        'ADDUSER': 'Voeg gebruiker toe',
        'SELECT': 'Selecteer',
        'ADDUSERWITHNAME': 'Voeg toe:',
        'DELETE': 'Verwijder document',
        'DELETEWARNING': 'Weet u zeker dat u dit document wil verwijderen?',
        USERS: {
            'HEADER': 'Huidige rollen',
            'INTRO': 'Dit zijn de huidige rollen voor dit document. Beheerders en auteurs kunnen documenten bewerken. Beoordelaars keuren een document goed voor publicatie. Abonnees krijgen een seintje wanneer een document gepubliceerd wordt.',
            'ADDAUTHOR': 'Maak auteur',
            'ADDREVIEWER': 'Maak beoordelaar',
            'ADDNOTIFIEDUSERS': 'Maak Abonnee',
        },
    },
    FOOTER: {
        FILES: 'bestanden',
        COMMENTS: 'opmerkingen',
        REFERENCES: 'bronnen',
        KEYQUESTION: 'onderbouwing'
    },
    GRADEREVIEW: {
        SUPPORTING_EVIDENCE: 'Supporting Evidence',
        DOCUMENTATION: 'Documentatie',
        COMMENT: 'Opmerkingen (optioneel)',
        ADD_DOCUMENTATION: 'Voeg een file toe',
        CHANGE_DOCUMENTATION: 'Pas de file aan',
        CHOOSE_ANSWER: 'Uw beoordeling',
        WAITING: 'Het nieuwe bestand zal hier verschijnen'
    },
    LITERATURE: {
        ADDREFERENCEMODAL: {
            'TITLE': 'Voeg bronnen toe',
            'TAGLINESEARCH': 'Zoek artikelen om toe te voegen',
            'TAGLINESELECT': 'Selecteer een of meer van de volgende bronnen en voeg ze toe',
        },
        PARAGRAPH: {
            'REFERENCES': 'Onderbouwing',
            'NOREFERENCES': 'Geen artikelen gevonden',
            'SOURCES': 'Bronnen',
            'GRADE' : 'Grade assesment'
        },
        REVIEW: {
            'DELETEROW': 'Verwijder rij(en)',
            'EDITROW': 'Maak concept-versie aan',
            'DUPLICATEROW': 'Kopieer rij(en)',
            'CHANGEKEYQUESTION': 'Pas PICO aan'
        },
        FINDINGS: 'Reviews in een gepubliceerd document',
        FINDINGSEDITABLE: 'In een concept versie',
        FINDINGSTOINCLUDE: 'Bevindingen die niet aan een conclusie zijn gekoppeld',
        SUPPORTING_ARTICLES: 'Ondersteunende artikelen',
        SUMMARYOFFINDINGS: 'Overzicht van de bevindingen',
        EVIDENCETABLE: 'Inhoudelijke beoordeling',
        REVIEWACTIONS: 'Leg de belangrijkste kenmerken van het artikel vast',
        REVIEWHEADER: 'Inhoudelijke beoordeling',
        REVIEWGRADEHEADER: 'Kwalitatieve beoordeling',
        ADDITIONALREVIEWACTIONS: 'Leg overige kenmerken van het artikel vast',
        FINDINGACTIONS: 'Koppel het artikel aan een of meer uitgangsvragen',
        NEXT: 'Volgende artikel',
        PREVIOUS: 'Vorig artikel',
        HASVOTED: 'heeft gestemd:',
        UPDATEVOTE: 'heeft de beoordeling aangepast naar:',
        INCLUDE: 'Includeren',
        EXCLUDE: 'Excluderen',
        DISCUSS: 'Discussiëren',
        STORE: 'Bewaren voor later',
        NOARTICLES: 'Er zijn geen nieuwe artikelen verschenen die aan uw zoekcriteria voldoen. U kunt met de hand artikelen toevoegen of uw criteria aanpassen.',
        VOTINGCOMPLETE: 'De abstract is door twee reviewers beoordeeld',
        VOTINGINCOMPLETE: 'Op dit moment hebben nog niet voldoende mensen het artikel beoordeeld. U kunt het artikel reeds verder reviewen in afwachting van de anderen',
        VOTINGHEADER: 'Beoordelingen',
        VOTINGHEADER_ABSTRACT: 'Beoordelingen op basis van abstract',
        VOTINGCOMPLETE_FT: 'De volledige tekst is door twee reviewers beoordeeld',
        VOTINGINCOMPLETE_FT: 'Op dit moment hebben nog niet voldoende mensen de volledige tekst van het artikel beoordeeld.',
        VOTINGHEADER_FT: 'Beoordelingen volledige tekst',
        ACTIONS: 'Acties',
        ACTIONSINTRO: 'Beoordeling',
        OPEN_PROTOCOL: 'Terug naar protocol',
        SOURCELIST: 'Gebruikte bronnen',
        ADD_BYHAND: 'Voeg artikel met de hand toe',
        ADD_PUBMED: 'Voeg artikel toe via PubMed',
        ADD_RIS_ARTICLE: 'Voeg artikel toe via RIS',
        SOURCESSELECTED: 'bronnen geselecteerd',
        SEARCHSTRATEGY_HEADER: 'Zoekstrategie',
        SEARCHSTRATEGY_TEXT: 'De volgende zoekterm is gebruikt',
        SEARCHSTRATEGY_RESULTS_TOTAL: 'Aantal zoekresultaten',
        SEARCHSTRATEGY_RESULTS_EXCLUDED: 'Geëxcludeerd',
        SEARCHSTRATEGY_RESULTS_INCLUDED: 'Geïncludeerd'
    },
    PARAGRAPH: {
        CONCLUSION: 'Conclusie',
        RECOMMENDATION: 'Aanbeveling',
        CONSIDERATION: 'Overweging',
        CONCLUSIONS: 'Conclusies',
        RECOMMENDATIONS: 'Aanbevelingen',
        CONSIDERATIONS: 'Overwegingen',
        EVIDENCE: {
            HIGHQUALITY: 'Hoge kwaliteit evidence',
            MODERATEQUALITY: 'Matige kwaliteit evidence',
            LOWQUALITY: 'Lage kwaliteit evidence',
            VERYLOWQUALITY: 'Zeer lage kwaliteit evidence',
        },
        EDIT: {
            ADDFILE: 'Voeg bestand toe',
            EDITTAGS: 'Tags beheren',
            CONNECTKEYQUESTION: 'Verbind met uitgangsvraag',
            CHANGEKEYQUESTION: 'Verbind met andere uitgangsvraag',
            WARNINGUPDATEKEYQUESTION: 'Een nieuwe versie van de uitgangsvraag is gepubliceerd. Haal de onderbouwing binnen en beoordeel of dit leidt tot aanpassingen van de aanbeveling',
            UPDATEKEYQUESTION: 'Haal nieuwe versie op',
            IMPORTREFERENCE: 'Importeer literatuurverwijzingen',
            ADDREFERENCE: 'Voeg literatuurverwijzing toe',
            SELECTREFERENCE: 'Wijs artikelen toe aan conclusie',
            LINKTOPROTOCOL: 'Link naar protocol',
            REMOVELINKTOPROTOCOL: 'Verwijder Link naar protocol',
            ADDGRADE: 'Voeg Grade-review toe',
            DELETEGRADE: 'Verwijder Grade-review',
            DELETE: 'Verwijder',
            ADDCHAPTER: 'Voeg hoofdstuk toe',
            ADDRECOMMENDATION: 'Voeg aanbeveling toe',
            ADDCONSIDERATION: 'Voeg overweging toe',
            ADDCONCLUSION: 'Voeg uitkomstmaat toe',
            ADDPARAGRAPH: 'Voeg paragraaf toe',
            CREATEMODELFROMSHEET: 'Importeer een model met een sheet',
            IMPORTEVIDENCIOMODEL: 'Importeer een evidencio model',
            CONVERTKEYQUESTION: 'Maak van hoofdstuk een uitgangsvraag',
            UPDATED: 'Bekijk verschil met gepubliceerde versie',
            NEW: 'Nieuw',
            RECENTLYUPDATED: 'Bekijk verschillen',
            COMPAREMODEL: 'Model gewijzigd'
        }
    },
    SUMMARY: {
        CONVERT: 'Maak verslag',
        CONVERTBACK: 'Maak gewone paragraaf'
    },
    MODEL: {
        EDIT: {
            UPDATESHEET: 'Model updaten vanuit sheet',
            UPDATELITERATURE: 'Literatuur updaten vanuit sheet',
            CREATECALCULATION: 'Berekening toevoegen',
            MODELEDITOR: 'Aanpassen in nieuwe model editor'
        },
        DETAIL: {
            TITLE: 'Nog geen uitkomsten',
            TEXT: 'Vul de vragen aan de linkerzijde in om relevante uitkomsten te zien',
            INFORMATION: 'Meer informatie',
            RESET: 'Nieuwe casus',
            SHOWALL: 'Alle mogelijke uitkomsten',
            SHOWSELECTED: 'Deze casus',
            NOFILTERS: 'Geen filter',
            SELECT: {
                SINGLE: 'zoek en selecteer',
                MULTIPLE: 'zoek en selecteer 1 of meer opties'
            },
            TREEVIEW: 'Toon meer informatie'
        },
        OUTCOME: {
            QUALITY_EVIDENCE: 'Niveau van evidentie',
            QUALITY_EVIDENCE_LEVEL_1: 'sterkte aanbeveling: sterk tegen',
            QUALITY_EVIDENCE_LEVEL_2: 'sterkte aanbeveling: conditioneel tegen',
            QUALITY_EVIDENCE_LEVEL_3: 'sterkte aanbeveling: conditioneel voor',
            QUALITY_EVIDENCE_LEVEL_4: 'sterkte aanbeveling: sterk voor',
            SOURCE_EVIDENCE: 'Bron',
            EDIT: {
                MODALTITLE: 'Verslag',
                MODALINTRO: 'Bij deze uitkomst kunt u een eigen tekst voor het verslag toevoegen.'
            },
            TREEVIEW: {
                TITLE: 'Overzicht',
                TEXT: 'Deze uitkomst wordt getoond in de volgende situaties',
                MODALTITLE: 'Overzicht relevante situaties',
                HEADERCONCLUSION: 'Wetenschappelijke onderbouwing',
                CONCLUSIONINTRO: 'Deze aanbeveling is gebaseerd op de beantwoording van de volgende uitgangsvraag.',
                MODALTEXT: 'De uitkomst wordt getoond bij de volgende (combinatie van) antwoorden',
                BACK: 'Terug',
                SHOWSOURCES: 'Toon bronnen',
                NOTEXT: 'Geen details aanwezig',
                CAPTION: {
                    0: 'In dit document',
                    1: 'Brondocument'
                }
            }
        }
    },
    COMMENT: {
        MARKASDONE: 'Markeer als ogelost',
        REOPEN: 'Heropen',
        REPLIES: 'Reacties',
        REPLY: 'Reageer',
        DONE: 'Opgelost'
    },
    HELP: {
        TITLE: 'Hulp',
        MODAL: 'Support formulier',
        PROBLEM: 'Meld een probleem'
    },
    TERMS: {
        TITLE: 'Algemene voorwaarden',
        CONFIRMATION: 'Ik heb de algemene voorwaarden gelezen en ga ermee akkoord',
        'ACCEPTED-ON': 'Geaccepteerd op',
        THANKS: 'Bedankt voor het accepteren van de algemene voorwaarden'
    },
    'TEXT-EDIT': {
        'COPY-LINK': 'Link kopiëren',
        'COPY-TEXT': 'Tekst kopiëren',
        'COPY-TEXT-NO-FORMATTING': 'Tekst kopiëren zonder opmaak',
        'OPEN-DOCUMENT': 'Document openen',
        'LINK-COPIED': 'Link naar klembord gekopieerd',
        'TEXT-COPIED': 'Tekst naar klembord gekopieerd',
        'CLICK-TO-EDIT': 'Klik om deze tekst te bewerken'
    },
    'NOT-FOUND': {
        HEADING: 'Niet gevonden',
        SUBHEADING: 'Sorry, maar deze pagina bestaat niet',
        HOME: 'Terug naar de start pagina'
    },
    INFOBOX: {
        ORIGINAL: {
            HEADER: 'Brondocument',
            INTRO: 'Dit document is gebaseerd op',
            DATE: 'Laatste update'
        },
        ACTIONS: {
            REJECT_REASON: 'Geef een reden waarom u het protocol wil afwijzen'
        }
    },
    NAVIGATION: {
        SEARCH: 'Zoek',
        HOME: 'Start',
        TEAM: 'Team',
        MESSAGES: 'Berichten',
        AGENDA: 'Agenda',
        LIBRARY: 'Bibliotheek',
        PROFILE: 'Profiel',
        HELP: 'Hulp',
        LOGOUT: 'Uitloggen',
        LANGUAGE: 'Taal',
        COLLAPSE: 'Invouwen',
        EXPAND: 'Uitvouwen'
    },
    AUTHORS: 'Auteurs',
    AVAILABLE: 'Beschikbaar',
    BACK: 'Terug',
    REPLY: 'Antwoord',
    CANCEL: 'Annuleren',
    CASE: 'Casus',
    CATEGORY: 'Categorie',
    CATEGORIES: 'Categorieën',
    CONFIRM: 'Bevestigen',
    COPY: 'Kopiëren',
    CREATE: 'Creëren',
    CURRENT: 'Huidig',
    'DASHBOARD-MANAGEMENT': 'Terug naar dashboard',
    DELETE: 'Verwijderen',
    GUIDELINE: 'Richtlijn',
    DOCUMENT: 'Document',
    DOCUMENTS: 'Documenten',
    DRAFT: 'Concept',
    SETTINGS_VERSION: 'Instellingen',
    LITERATURE_VERSION: 'Literatuur',
    DUTCH: 'Nederlands',
    EDIT: 'Bewerken',
    EN: 'Engels',
    ENGLISH: 'Engels',
    ERROR: 'Fout',
    EXISTING: 'Bestaande',
    FILTER: 'Filter',
    LOADING: 'Bezig met laden',
    MANAGE: 'Beheer',
    'MORE-INFORMATION': 'Meer informatie',
    NAME: 'Naam',
    NL: 'Nederlands',
    NO: 'Nee',
    'NO-RESULTS': 'Geen resultaten',
    NONE: 'Geen',
    OK: 'OK',
    'OPEN LINK': 'Link openen',
    OPTIONS: 'Opties',
    PAGE: 'Pagina',
    'PAGE-SIZE': 'Pagina grootte',
    PREVIEW: 'Voorbeeld',
    READMORE: 'Lees verder',
    RESET: 'Resetten',
    SAVE: 'Bewaren',
    SEARCH: 'Zoek',
    SEARCHTEXT: 'All resultaten',
    SEARCHTITLE: 'Ga naar de zoekpagina',
    SELECT: 'Selecteer',
    SIGNIN: 'Inloggen',
    SIGNOUT: 'Uitloggen',
    SIGNUP: 'Registreren',
    'SINGLE-SIGNON': 'Single Sign-on',
    SUBMIT: 'Indienen',
    SUCCESS: 'Succes',
    TITLE: 'Titel',
    TYPE: 'Soort',
    TYPESOMETHING: 'Typ om te editten',
    UPDATE: 'Wijzigingen opslaan',
    VERSIONS: 'Versies',
    VERSION: {
        COMPARE: 'Vergelijk met vorige versie',
        OLDERVERSIONS: 'Eerdere versies',
        STOREDDRAFTVERSIONS: 'Opgeslagen conceptversies'
    },
    YES: 'Ja',
    TAP_TO_CHANGE_TITLE: 'Klik om de naam te wijzigen',
    PLEASE_SELECT: 'selecteer',
    CLOSE: 'sluit'
};
