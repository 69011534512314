<div class="row force-fit" >
    <div class="layout">
        <div class="menuBar">
            <div style="width: 100%;">
                <div class="headingProtocolName ">
                    <div class="col-8" style="vertical-align: middle;">
                        <span class="protocol-title" style="padding-right: 6px;">{{protocolDetails?.title}}</span><span>| </span>
                        <span class="mainAuthors">
                            {{protocolDetails?.author}}
                        </span>
                    </div>
                    <div class="protocolTabs col-4">
                        <div class="pull-right" *ngIf="protocolDetails && protocolDetails.isLiterature">
                            <a class="topbarLink"
                                    [routerLink]= "'/protocols/' + protocolId"
                                    style="cursor: pointer;"
                                    >
                                    {{'SETTINGS_VERSION' | translate}}
                            </a>
                        </div>
                        <div class="pull-right" *ngIf="protocolDetails && !protocolDetails.isLiterature">
                            <ng-container *ngFor="let params of getProtocolLink(versions)">
                                <a class="topbarLink" *ngIf="params.version === 'Current'"
                                            [routerLink]= "'/protocols/' + protocolId"
                                            [queryParams] = params
                                            style="cursor: pointer;"
                                            >

                                            {{'PROTOCOLS.MARGIN.BUTTON.CURRENT-VERSION' | translate}}
                                </a>
                                <span *ngIf="getProtocolLink(versions).length > 1 && params.version === 'Current'">|</span>
                                <a class="topbarLink" index="row" *ngIf="params.version === 'Draft'"
                                            [routerLink]= "'/protocols/' + protocolId"
                                            [queryParams] = params
                                            style="cursor: pointer;"
                                            >

                                            {{'PROTOCOLS.MARGIN.BUTTON.DRAFT' | translate}}
                                </a>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
        <!--    <div class="layout__page">-->
        <main id="content" class=""[ngClass]="{ waiting: loading }">
            <div class="page -small-top moveDown" >      
                        
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-3 position-fixed" id="sticky-sidebar">
                            <div class="top-navigationBar">
                                <hr/>
                                <ng-container *ngFor="let key of getLiteraturesKeys(); trackBy: trackByFn">
                                    <ul class="subnavigation">
                                        <ng-container *ngTemplateOutlet="menu; context: { key: key, ctx: literatures[key] }">
                                        </ng-container>
                                    </ul>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col offset-3" id="main">
                            <div class="">
                                <div *ngIf="loading" id="loading" style="display: block;">
                                    <div class="spinner">
                                        <div class="dot1"></div>
                                        <div class="dot2"></div>
                                    </div>
                                </div>
                                <section *ngIf="!loading" class="page__section -no-bottom -transparent">
                                    <ng-container *ngFor="let key of getLiteraturesKeys(); trackBy: trackByFn">
                                        <ng-container *ngTemplateOutlet="default; context: { key: key, ctx: literatures[key] }">
                                        </ng-container>
                                    </ng-container>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>

<ng-template #menu let-ctx="ctx" let-key="key">
    <li class="subnavigation__item" *ngIf="ctx.rowData.length > 0 || key === 'new_articles'">
        <a class="subnavigation__link"
        (click)="scrollToSection(key)" > 
            {{ ctx.label }} 
        </a>
    </li> 
</ng-template>

<ng-template #default let-ctx="ctx" let-key="key">
    <div *ngIf="ctx.rowData.length > 0 || key === 'new_articles'">
        <div id="{{key}}" class="dashboard__title"></div>
        <div class="col" *ngIf="isActionsHasAddNew(ctx.actions)">
            <a
                type="button"
                class="button cursor-pointer mr-2 pull-right "
                (click)="onClickAddRisArticle()"
            >
            {{'LITERATURE.ADD_RIS_ARTICLE' | translate}}
            </a>
            <a
                type="button"
                class="button cursor-pointer mr-2 pull-right "
                (click)="onClickAddArticle()"
            >
            {{'LITERATURE.ADD_PUBMED' | translate}}
            </a>
            <a
                type="button"
                class="button cursor-pointer mr-2 pull-right "
                (click)="onClickAddNonPubmedArticle()"
            >
            {{'LITERATURE.ADD_BYHAND' | translate}}
            </a>
        </div>

        <section class="">
            <div class="row">
                <div class="col">
                    <h3 class="dashboard__title">{{ ctx.label }}</h3>
                </div>
            </div>

            <div class="noArticleMessage" *ngIf="key === 'new_articles' && ctx.rowData.length === 0; else articlesAvailable">
                <p class="wysiwyg" style="font-size: .875em;">{{'LITERATURE.NOARTICLES' | translate}}</p>
                <br />
            </div>


            <ng-template #articlesAvailable>
           
                <ag-grid-angular
                    #agGrid
                    style="width: 100%"
                    class="ag-theme-alpine"
                    [columnDefs]="ctx.columnDefs"
                    [rowData]="ctx.rowData"
                    domLayout='autoHeight'
                    [gridOptions]="gridOptions"
                    [pagination]=true
                    [paginationPageSize]=50
                    [defaultColDef]="defaultColDef"
                    (cellClicked)="onCellClicked($event)"
                >
                </ag-grid-angular>
            </ng-template>
        </section>
    </div>
</ng-template>
