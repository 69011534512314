import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';

import * as fromStore from './../../store';
import { User } from '../../../../models/user.interface';
import { Organization } from '../../../../models/organization.interface';
import { ActivatedRoute } from '@angular/router';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-organisation',
    templateUrl: './organisation.component.html'
})
export class OrganisationComponent implements OnInit {
    teamId: string;
    users$: Observable<User[]>;
    organization$: Observable<Organization>;
    name: string;

    constructor(
        private store: Store<fromStore.OrganisationsFeatureState>,
        private permissionsService: NgxPermissionsService,
        private route: ActivatedRoute
        ) {
        this.route.paramMap.subscribe(params => {
            this.teamId = params.get('id');
        });
        }

    ngOnInit() {
        this.store.dispatch(new fromStore.LoadUsers(this.teamId));
        this.store.dispatch(new fromStore.LoadOrganization());
        this.users$ = this.store.select(fromStore.getUsers);
        this.organization$ = this.store.select(fromStore.getOrganization);

        this.organization$.subscribe(organization => {
            if (organization) {
                this.name = organization.name;
                if (organization.roles) {
                    const roles = organization.roles.reduce((acc, rl: any) => {
                        return [...acc, rl.role];
                    }, []);
                    this.permissionsService.addPermission(roles);
                }
            }
        });
    }

    handleEventBus(event) {
        const { action, payload } = event;
        switch (action) {
            case 'removeUser':
                this.handleRemoveUser(payload);
                break;
            case 'setAdmin':
                this.handleAddOrRemoveRole(action, payload);
                break;
            case 'removeAdmin':
                this.handleAddOrRemoveRole(action, payload);
                break;
            case 'setStatistics':
                this.handleAddOrRemoveRole(action, payload);
                break;
            case 'removeStatistics':
                this.handleAddOrRemoveRole(action, payload);
                break;
            case 'setReviewer':
                this.handleAddOrRemoveRole(action, payload);
                break;
            case 'removeReviewer':
                this.handleAddOrRemoveRole(action, payload);
                break;
            case 'setLiteratureReviewer':
                this.handleAddOrRemoveRole(action, payload);
                break;
            case 'removeLiteratureReviewer':
                this.handleAddOrRemoveRole(action, payload);
                break;
            case 'setLibraryAdmin':
                this.handleAddOrRemoveRole(action, payload);
                break;
            case 'removeLibraryAdmin':
                this.handleAddOrRemoveRole(action, payload);
                break;
            default:
                break;
        }
    }
    handleRemoveUser(payload) {
        const { userId } = payload;
        this.store.dispatch(new fromStore.RemoveUser({ userId }));
    }

    handleAddOrRemoveRole(action, payload) {
        const { userId } = payload;
        this.store.dispatch(new fromStore.AddOrRemoveRole({ action, userId }));
    }
}
 