import { Injectable } from '@angular/core';


@Injectable()
export class UserService {
    constructor() {}

    isAliiUser() {
        const token = localStorage.getItem('authHash');
        if(!token) {
            return false;
        }
        try {
            var payload = JSON.parse(atob(token.split('.')[1]))
            var username = payload.username;
            if(username.endsWith("alii.care") || username.endsWith(".local")){
                return true;
            }
            return false;
        } catch (error) {
            return false
        }
    }
}