import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

// Development only
import { diff, detailedDiff } from 'deep-object-diff';
import _ from 'lodash';

import { Paragraph } from '@alii-web/models/paragraph.interface';
import { Outcome, OutcomeType } from '@alii-web/models/outcome.interface';
import { ParagraphsService } from '@alii-web/services';
import { environment } from '@environments/environment';
import { Question } from '@alii-web/models/question.interface';
import { NgbDateStruct, NgbTimeStruct, NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';

// Development only
import { ModelsService, ProfileService, ProtocolsService } from '../../../../../../services';
import { Option } from '@alii-web/models/question.interface';
import { IframeModalComponent } from '../../../../entry-components/iframe-modal/iframe-modal.component';


const cn = 'ModelDetailComponent';

type OutcomesView = 'vertical' | 'horizontal';

// Any display width less than BREAKPOINT_WIDTH_PX will cause the outcomes list to appear below the
// questions instead of on the side.
const BREAKPOINT_WIDTH_PX = 992;

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-model-detail',
    templateUrl: './model-detail.component.html',
    styleUrls: ['./model-detail.component.scss']
})
export class ModelDetailComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @Input()
    parentId: string;

    @Input()
    findings: Question[];

    @Input()
    outcomes: Outcome[];

    @Input()
    tagList: any[];

    @Input()
    modelSource: string;

    @Input()
    viewByPopulation: boolean;

    @Input()
    populations: any;

    @Input()
    populationId: string;

    @Input()
    paragraphId: string;

    @Input()
    paragraph: Paragraph;

    @Input()
    showIdentifiers: boolean;

    @Input()
    sidebarCollapsed: boolean;

    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('content') contentWindow: ElementRef;

    arrayIsCollapsedPopulations: boolean[];
    showModel = true;
    showAllOutcomes = false;
    isEditAble = false;

    showMoreOutcomes = false;

    // Side-by-side outcomes view
    outcomesView: OutcomesView;
    outcomesShowToggleView = environment.featureFlags.outcomesShowToggleView;
    outcomesSideBySide = environment.featureFlags.outcomesSideBySide;

    // Typeahead dropdown list
    dropdownOptions = {};
    selectedOptions = {};
    originalOptions = {};
    // A value of -1 means disabled.
    typeaheadMaxOptions = environment.featureFlags.typeaheadMaxOptions || 10;

    // TODO: Flags for tracking pending calls for displaying/hiding spinner, this should
    // be done using the store state changes instead.
    outcomeListPending = false;
    resetModelPending = false;
    updateModelPending = false;
    showAllOutcomesPending = false;
    activeFilters: any[] = [];
    subscriptions: Subscription[] = [];

    lang = {};

    // Development only
    private development: boolean;
    private updateModelRequest: any;
    private getProtocolRequest: any;
    
    private showFeedbackForm = false;
    private isAkwa = false;


    @HostListener('window:resize', ['$event']) handleResize() {
        if (this.outcomesSideBySide) {
            this._handleResize();
        }
    }

    constructor(
        private paragraphService: ParagraphsService,
        private sanitizer: DomSanitizer,
        private renderer: Renderer2,
        private hostElement: ElementRef,
        private cdr: ChangeDetectorRef,
        private translateService: TranslateService,
        // Development only
        private modelsService: ModelsService,
        private protocolsService: ProtocolsService,
        private modalService: NgbModal,
        private profileService: ProfileService
    ) {}

    ngOnInit(): void {
        this.outcomes =  JSON.parse(JSON.stringify(this.outcomes));
        this.isAkwa = localStorage.getItem("customStyle") === 'akwa';
        if (this.isAkwa) {
            this.profileService.shouldAskForFeedback().subscribe((result => {
                this.showFeedbackForm = result;
            }));    
        }
        this.activeFilters = []

        // this.development = !environment.production;

        this.outcomesView = this.outcomesSideBySide ? 'vertical' : 'horizontal';
        this.arrayIsCollapsedPopulations = Array.isArray(this.populations)
            ? this.populations.reduce((acc, pop) => {
                  return {
                      ...acc,
                      [pop.id]: true
                  };
              }, {})
            : {};

        // Language translations
        const words = [
            'title',
            'test',
            'showall',
            'reset',
            'information',
            'select.single',
            'select.multiple',
            'treeview'
        ];
        const keys = words.map(w => 'MODEL.DETAIL.' + w.toUpperCase());
        this.subscriptions.push(
            this.translateService
                .stream(keys)
                .subscribe(results =>
                    Object.keys(results).forEach((key, index) => (this.lang[words[index]] = results[key]))
                )
        );

        // Development only
        if (this.development) {
            // Some sanity checks, just in case.
            this._sanityChecks();
        }
    }

    ngAfterViewInit() {
        this.setOutcomeLists()
        if (this.outcomesSideBySide) {
            setTimeout(() => {
                this._handleResize();
                // Must mark to force re-render initial resize call.
                this.cdr.markForCheck();
            }, 200);
        }
    }

    setOutcomeLists() {
        this.outcomes.forEach((outcome)=> {
            if (outcome.outcomeList) {
                outcome.outcomeList.forEach(element => {
                    this.outcomeListSelected[element.id] = element.selected
                });
            }
        } )
    }


    // the show next model for now is only implemented for the rapp. that's why this 
    // hardcoded guid is in here
    showNextModelLink() {
        return window.location.href.indexOf("90ce114f-69fa-4364-a2a0-efbb57733e76") > -1;
    }

    showFeedFormForAkwa(outcomes) {
        if (outcomes.length !== 0 && this.isAkwa && this.showFeedbackForm) {
            setTimeout(() => {
                this.profileService.getFeedbackForm().subscribe(result => {
                    const modalRef = this.modalService.open(IframeModalComponent, {size: 'xl'});
                    modalRef.componentInstance.externalUrl = result.url + '&app=web';
                    this.showFeedbackForm = false;
                })
            }, 1500);
        }

    }

    ngOnChanges(changes: SimpleChanges) {
        // Update typeahead when changes to the findings have been detected.
        if (changes.findings) {
            this._initTypeahead();
        }

        // Development only
        if (this.development) {
            const { findings } = changes;
            if (findings && findings.currentValue && !findings.firstChange) {
                if (this.getProtocolRequest) {
                    this._checkGetProtocol(findings.currentValue);
                }
                if (this.updateModelRequest) {
                    this._checkUpdateModel(findings.currentValue);
                }
            }
        }

        if (changes.outcomes) {
            const outcomes = changes.outcomes.currentValue;

            if (outcomes) {
                this.showFeedFormForAkwa(outcomes);
                
                if (this.resetModelPending) {
                    if (!outcomes.length) {
                        this.resetModelPending = false;
                        this.setOutcomeLists();
                    }
                }
                if (this.updateModelPending) {
                    this.updateModelPending = false;
                    this.setOutcomeLists();
                }
                if (this.showAllOutcomesPending) {
                    this.showAllOutcomesPending = false;
                }
                if (this.outcomeListPending) {
                    this.outcomeListPending = false;
                }
                setTimeout(() => {
                    this.applyFilter()
                    }, 0);
                
            }
        }

        if (changes.sidebarCollapsed && !changes.sidebarCollapsed.firstChange) {
            setTimeout(() => {
                this._handleResize();
                // Must mark to force re-render initial resize call.
                this.cdr.markForCheck();
            }, 1000);
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }

    formatGradeKey(key: string) {
        return key.replace(/_/g, ' ').replace('GRADE - ', '');
    }

    // TODO: Duplicated code paragraph-footer.component.ts
    summaryOfFinding(finding) {
        const ignoreColumns = ['action', 'id', 'user', 'user_name', 'status', 'actionLabel'];

        return Object.keys(finding)
            .filter(key => !ignoreColumns.includes(key))
            .filter(key => finding[key] !== null)
            .filter(key => finding[key].toString() !== '')
            .map(key => {
                // TODO: fix this in backend
                const value = finding[key].toString().replace('illegal reference: ', '');
                return {
                    key,
                    value
                };
            });
    }

    onClickRelatedParagraph(paragraphId) { 
        var element = document.getElementById("heading-" + paragraphId)
        element.scrollIntoView()
    }

    onSelectOutcome(outcome) {
        if (!outcome.outcomeList) {
            const outcomeId = outcome.id;
            this.outcomeSelected[outcomeId] = !this.outcomeSelected[outcomeId];

            const action = {
                type: 'onSelectOutcome',
                payload: {
                    outcomeId,
                    selected: this.outcomeSelected[outcomeId]
                }
            };

            this.eventBus.emit(action);
        }
    }

    onSelectOutcomeList(outcomeList) {
        const outcomeId = '' + outcomeList.id;
        const modelId = this.paragraph.id;
        const populationId = this.populationId;
        this.outcomeListSelected[outcomeId] = !this.outcomeListSelected[outcomeId];
        this.outcomeListPending = true;
        
        const action = {
            type: 'onSelectOutcomeList',
            payload: {
                populationId,
                outcomeId,
                modelId,
                action: outcomeList.selectionType === 'select' ? 'select' : this.outcomeListSelected[outcomeId] ? 'add' : 'remove'
            }
        };

        this.eventBus.emit(action);
    }


    evidenceQualityToText(quality) {
        let text = quality;
        this.translateService.get('MODEL.OUTCOME.QUALITY_EVIDENCE_LEVEL_' + quality).subscribe(translation => text = translation);
        return text;
    }

    onShowMoreOutcomes(event: MouseEvent) {
        this.showMoreOutcomes = !this.showMoreOutcomes;
        event.stopPropagation();
        return false;
    }

    onSelectDate(date: NgbDate, i, option, findingId) {
        this.updateModelPending = true;
        const id = option.id;
        const value = date.day+"/"+date.month+"/"+date.year;

        let payload = { option: { id, value, selected: true} };
        this.eventBus.emit({
            type: 'onUpdateModel',
            payload: {
                ...payload,
                showMessageIsLoading: this.outcomesView === 'horizontal',
                modelId: this.paragraphId,
                findingId,
                ...(this.populationId && { populationId: this.populationId })
            }
        });
    }

    onChangeOption(event, i, option, findingId, questionType = '') {
        let payload;
        const id = option.id;
        const multiple = questionType === 'multiple';

        if (option.type === 'continuous') {
            // slider? set value
            const value = event.target.value;
            payload = { option: { id, value, selected: value !== '' } };
        } else if (option.type === 'string') {
            const value = event.target.value;
            payload = { option: { id, value, selected: value !== '' } };
        }
        else {
            // (multi-)select? toggle selection
            payload = { multiple, option: { id, selected: !option.selected } };
        }

        this.updateModelPending = true;
        this.showAllOutcomes = false

        this.eventBus.emit({
            type: 'onUpdateModel',
            payload: {
                ...payload,
                showMessageIsLoading: this.outcomesView === 'horizontal',
                modelId: this.paragraphId,
                findingId,
                ...(this.populationId && { populationId: this.populationId })
            }
        });
    }

    onClickArticle(article) {
        const { articleId } = article;

        const viewArticleAction = {
            type: 'onClickArticle',
            payload: {
                articleId
            }
        };
        this.eventBus.emit(viewArticleAction);
    }

    onShowHelpText(event, helpText, identifierText) {
        if (identifierText) {
            helpText = helpText + identifierText;
        }
        const helpTextAction = {
            type: 'openHelpText',
            payload: {
                helpText
            }
        };

        this.eventBus.emit(helpTextAction);
        event.stopPropagation();
        return false;
    }

    openOutcomeTreeview(event, outcome: Outcome) {
        const openTreeAction = {
            type: 'openOutcomeTreeview',
            payload: {
                outcome,
                populationId: this.populationId
            }
        };
        this.eventBus.emit(openTreeAction);
        event.stopPropagation();
        return false;
    }

    openOutcomeEdit(event, outcome: Outcome) {
        const openOutcomeEditAction = {
            type: 'openOutcomeEdit',
            payload: {
                outcome,
                populationId: this.populationId,
                outcomeListSelected: this.outcomeListSelected,
                modelId: this.paragraph.id
            }
        };
        this.eventBus.emit(openOutcomeEditAction);
        event.stopPropagation();
        return false;
    }

    resetModel() {
        this._initTypeahead();
       
        // Display the spinner until completed, see ngOnChanges.
        this.resetModelPending = true;
        this.eventBus.emit({
            type: 'resetModel',
            payload: { modelId: this.paragraphId }
        });

    }

    hasOutcomeIcon(outcome) {

        let type = outcome.type
        if (outcome.icon) {
            if(outcome.icon === 'recommendation')   { 
                return false
            }
            return true
        }
        else if (type === 'other' ||
            type === 'not recommended'||
            type === 'exclamation'||
            type === 'diagnostic'||
            type === 'therapeutic'
            ) {
                return true
        } 
        return false
    }

    outcomeIcon(outcome) {
        if (outcome.icon === 'not recommended')
            {return 'icon-minus-circle shiftLeft';}
        else if (outcome.icon === 'exclamation')
            {return 'icon-exclamation-triangle shiftLeft';}
        else if (outcome.icon === 'diagnostic')
            {return 'icon-heartbeat shiftLeft';}
        else if (outcome.icon === 'therapeutic')
            {return 'icon-medkit shiftLeft';}
        return 'icon-check shiftLeft';
    }

    resetPatient() {
        this.eventBus.emit({
            type: 'handleResetPatient',
            payload: ''
        });
        this.setOutcomeLists()
        return false;
    }

    newFindings(sources) {
        sources.forEach(source => {
            source.finding.forEach(finding => {
                if (finding.status === 'new') {
                    return true;
                }
            });
        });
        return false;
    }

    isActiveTag(tag) {
        const index = this.activeFilters.indexOf(tag.title, 0);
        return index > -1 
    }

    hasActiveFilters(tag) {
        return this.activeFilters.length
    }

    resetFilters() {
        this.activeFilters = []
        const outcomes = document.querySelectorAll('.outcomeItem');
        outcomes.forEach(e => {
            e.parentElement.parentElement.classList.remove('hidden');
        })
    }

    applyFilter() {
        if (this.activeFilters.length) {
            const outcomes = document.querySelectorAll('.outcomeItem');
            outcomes.forEach(e => {
                e.parentElement.parentElement.classList.add('hidden');
                this.activeFilters.forEach(filter => {
                    if(e.getElementsByClassName(filter).length) {
                        e.parentElement.parentElement.classList.remove('hidden');
                    }
                }) 
            })
        } else { this.resetFilters() }
    }

    filterOutComes(tag) {
        this.activeFilters = [tag.title];

        /*
        // the old way allowed for more than 1 filter at the 
        // same time, but for the time being we disabled this
        // feature by customer request.

        const index = this.activeFilters.indexOf(tag.title, 0);
        if (index > -1) {
            this.activeFilters.splice(index, 1);
        } else {
            this.activeFilters.push(tag.title);
        }
        */
        this.applyFilter()
    }


    linkToNextModel(){
        return "/protocols/6a94e5aa-fe4c-4462-9f6c-c1ff53bd3188?version=Current&populationId=" + this.populationId 
    }

    getScoreColor(score) {
        const colors = ["#990000", "#E5841E", "#B6D7A8", "#35824E"];
        return colors[score-1];
    }

    getColoredBalls(score) {
        return Array(+score).fill(0).map((x,i)=>i);
    }

    getGreyBalls(score) {
        let noScore = 4 - +score
        return Array(noScore).fill(0).map((x,i)=>i);
    }

    outcomeSelected(outcomeId) {
        return outcomeId in this.outcomeSelected && this.outcomeSelected[outcomeId];
    }

    outcomeListSelected(outcomeListId) {
        return outcomeListId in this.outcomeListSelected && this.outcomeListSelected[outcomeListId];
    }

    togglePopulationProperty(populationId) {
        this.populationPropertiesVisible[populationId] = !this.populationPropertiesVisible[populationId];
    }

    populationPropertiesVisible(populationId) {
        return populationId in this.populationPropertiesVisible && this.populationPropertiesVisible[populationId];
    }

    toggleFindings(articleId) {
        this.findingsVisible[articleId] = !this.findingsVisible[articleId];
    }

    findingsVisible(articleId) {
        return articleId in this.findingsVisible && this.findingsVisible[articleId];
    }

    toggleShow() {
        this.showModel = !this.showModel;
    }

    numberToList(num) {
        return Array.from(Array(+num), (x, i) => i);
    }

    objectToArray(o) {
        return o ? Object.values(o) : [];
    }

    onToggleShowAllOutcomes() {
        this.showAllOutcomes = !this.showAllOutcomes;

        if(this.showAllOutcomes) {
            this.showAllOutcomesPending = true;
        }

        const action = this.showAllOutcomes
            ? {
                  type: 'handleShowAllOutcomes',
                  payload: {
                      modelId: this.paragraphId
                  }
              }
            : {
                  type: 'handleShowOutcomesByPopulationId',
                  payload: {
                      modelId: this.paragraphId
                  }
              };

        this.eventBus.emit(action);
    }

    safeText(text) {
        return this.sanitizer.bypassSecurityTrustHtml(text);
    }

    iconOutcomeType(type: OutcomeType) {
        let result = '';
        const list: Array<{ type: OutcomeType; name: string | null }> = [
            { type: 'recommendation', name: null },
            { type: 'warning', name: 'exclamation' },
            { type: 'information', name: 'info' },
            { type: 'selection', name: null }
        ];

        const found = list.find(item => item.type === type);
        if (found && found.name) {
            result = `<span class="button__icon"><span class="icon-${found.name}"></span></span>`;
        }

        return this.sanitizer.bypassSecurityTrustHtml(result);
    }

    clickIconOutcomeType(event: MouseEvent, type: OutcomeType) {
        event.stopPropagation();
        return false;
    }

    findingsObjectToArray(findingsObject) {
        const findings = this.objectToArray(findingsObject);
        findings.sort((a, b) => (a['index'] > b['index'] ? 1 : -1));
        return findings;
    }

    sortByScore(list: any[]): any[] {
        return list.slice().sort((a, b) => (a.score < b.score ? 1 : b.score < a.score ? -1 : 0));
    }

    sortByTitle(list: any[]): any[] {
        return list.slice().sort((a, b) => (a.title < b.title ? -1 : b.title < a.title ? 1 : 0));
    }

    getOptionId(i: number, j: number) {
        return `option-${i}-${j}`;
    }

    // Typeahead: show or hide the given typeahead dropdown list.
    showTypeahead(i: number) {
        return this.typeaheadMaxOptions === -1
            ? false
            : this.dropdownOptions[i].length &&
                  this.dropdownOptions[i].length + this.selectedOptions[i].length > this.typeaheadMaxOptions;
    }

    // --- TOGGLE OUTCOMES VIEW --- //

    // ToggleOutcomesView: switch between horizontal and vertical views.
    toggleOutcomesView() {
        this.outcomesView = this.outcomesView === 'vertical' ? 'horizontal' : 'vertical';
    }

    // --- TYPEAHEAD DROPDOWN --- //

    // Typeahead: option has been selected from the typeahead dropdown list, move it to list above and click in
    // order to select.
    onSelected(event: any) {
        const { id, item } = event;
        const ids = id.split('-');
        const i = ids[ids.length-1];
       
        if (ids[1]) {
            const i = ids[ids.length-1];
            this.selectedOptions[i].push(item);
            this.dropdownOptions[i] = this.dropdownOptions[i].filter(option => option !== item);
            const j = this.originalOptions[i].findIndex(x => x.name == item.name);
            this.clickOption(i, j);
        } else {
            console.warn(`${cn} onSelected invalid id='${id}'`);
        }
    }

    // Typeahead: whether or not to the option is displayed above the typeahead dropdown list.
    isSelected(i, name) {
        if (this.selectedOptions[i]) {
            return this.selectedOptions[i].find(x => x.name == name);
        } else {
            return false;
        }
    }

    // Typeahead: whether or not to display the option above the typeahead dropdown list.
    showOption(i: number, name: string) {
        if (this.showTypeahead(i)) {
            return this.selectedOptions[i].find(x => x.name == name);
        } else {
            return true;
        }
    }

    // Typeahead: given option is moved back to the typeahead dropdown list and removed from view.
    onClose(i, name) {
        this.selectedOptions[i] = this.selectedOptions[i].filter(option => option.name !== name);

        // Retain the original order when moving options back.
        this.dropdownOptions[i] = [];
        this.originalOptions[i].forEach(option => {
            if (!this.selectedOptions[i].includes(option)) {
                this.dropdownOptions[i].push(option);
            }
        });
    }

    clickOption(i: number, j: number) {
        // Attempt to access option element every 200ms until either it is found or max time has been
        // reached, then click the option.
        const TIMEOUT = 200;
        const MAX = 5000;
        let msecs = 0;
        const sel = '#' + this.getOptionId(i, j);


        // TODO: Replace with RXJS timer take until which is more elegant.
        const timerId = setInterval(() => {
            const el = this.hostElement.nativeElement.querySelectorAll(sel);
            if (el && el[0]) {
                // Found option so click it.
                const option = el[0];
                option.click();
                clearInterval(timerId);
            } else {
                // Not found.
                msecs += TIMEOUT;
                if (msecs >= MAX) {
                    console.warn(`${cn} clickOption(i=${i},j=${j}) cannot find option sel='${sel}'`);
                    clearInterval(timerId);
                }
            }
        }, TIMEOUT);
    }

    get pending() {
        return this.resetModelPending || this.updateModelPending || this.showAllOutcomesPending;
    }

    // --- Keep icon question glued to end of string: start --- //

    // Don't allow icon to appear alone on a separate line, ensure that it is always preceded by at least one word. This
    // is done by splitting the given text using the startOf() and endOf() functions and inserting the last word and the
    // icon within spans with whitespace is nowrap style.

    // Return the first words of a string not including the last word. If null, empty string or only one word,
    // then return the empty string.
    startOf(text: string) {
        text = text || '';
        const words = text.split(' ');
        return words.splice(0, words.length - 1).join(' ');
    }

    // Return the last word of a string. If null or empty string, then return the empty string. If only one word,
    // then return the word.
    endOf(text: string) {
        text = text || '';
        const words = text.split(' ');
        return words.length ? words.splice(words.length - 1).join(' ') : '';
    }

    // --- Keep icon question glued to end of string: end --- //

    trackByFn = (index, item) => item.id || index;

    // Typeahead: initialize the typeahead dropdown if needed, e.g. when number of options greater than max,
    // and populate the dropdown with unselected options and display the remaining above the dropdown.
    private _initTypeahead() {
        this.findingsObjectToArray(this.findings).forEach((finding: any, i) => {
            this.dropdownOptions[i] = [];
            this.selectedOptions[i] = [];
            this.originalOptions[i] = [];
            const options = this.objectToArray(finding.options).filter((option: any) =>
                ['categorical', 'discrete'].includes(option.type)
            );
            if (this.typeaheadMaxOptions !== -1 && options.length > this.typeaheadMaxOptions) {
                options.forEach((option: any) => {
                    const name = option.title;
                    const synonyms = option.synonyms || [];
                    this.originalOptions[i].push({name, synonyms});
                    if (option.selected) {
                        this.selectedOptions[i].push({name, synonyms});
                    } else {
                        this.dropdownOptions[i].push({name, synonyms});
                    }
                });
            }
        });
    }

    private _handleResize() {
        const width = this.contentWindow.nativeElement.clientWidth;
        const outcomesView = width < BREAKPOINT_WIDTH_PX ? 'horizontal' : 'vertical';
        if (this.outcomesView !== outcomesView) {
            this.outcomesView = outcomesView;
        }

        if (this.outcomesView === 'vertical') {
            // Attempt to access elements every 200ms until either they are found or max time has been reached.
            const TIMEOUT = 200;
            const MAX = 5000;
            let msecs = 0;
            // TODO: Replace with RXJS timer take until which is more elegant.
            const timerId = setInterval(() => {
                if (width < BREAKPOINT_WIDTH_PX) {
                    // Window width decreased too small in the meantime, so we can stop looking.
                    clearInterval(timerId);
                } else {
                    const elModelViewOuterBlock = document.getElementById('model-view-outer-block-' + this.paragraphId);
                    const elOutcomeViewOuterBlock = document.getElementById(
                        'outcome-view-outer-block-' + this.paragraphId
                    );
                    const elOutcomeViewInnerBlock = document.getElementById(
                        'outcome-view-inner-block-' + this.paragraphId
                    );
                    if (elModelViewOuterBlock && elOutcomeViewOuterBlock && elOutcomeViewInnerBlock) {
                        // Found.
                        if (elOutcomeViewOuterBlock.offsetHeight < elModelViewOuterBlock.offsetHeight) {
                            // The height of the outcome block has become smaller then the height of the model
                            // block so we need to reset the blocks to have equal height for scrolling.
                            this.renderer.setStyle(
                                elOutcomeViewOuterBlock,
                                'height',
                                elModelViewOuterBlock.offsetHeight + 'px'
                            );
                        }
                        // Enable outcomes to scroll by enabling the class if not already present.
                        if (!elOutcomeViewOuterBlock.classList.contains('outcome-view-outer-block')) {
                            this.renderer.addClass(elOutcomeViewOuterBlock, 'outcome-view-outer-block');
                        }
                        if (!elOutcomeViewInnerBlock.classList.contains('outcome-view-inner-block')) {
                            this.renderer.addClass(elOutcomeViewInnerBlock, 'outcome-view-inner-block');
                        }
                        clearInterval(timerId);
                    } else {
                        // Not found.
                        msecs += TIMEOUT;
                        if (msecs >= MAX) {
                            console.warn(`${cn} _setupInnerScrolling: cannot find elements`);
                            clearInterval(timerId);
                        }
                    }
                }
            }, TIMEOUT);
        }
    }

    // Development only
    private _sanityChecks() {
        // Check get protocols
        this.subscriptions.push(
            this.protocolsService.request$.subscribe(request => {
                if (request && request.response && request.action === 'getProtocol') {
                    this.getProtocolRequest = request;
                    this._checkGetProtocol(this.findings);
                }
            })
        );

        // Check update model
        this.subscriptions.push(
            this.modelsService.request$.subscribe(request => {
                if (request && request.response && request.action === 'updateModel') {
                    this.updateModelRequest = request;
                }
            })
        );
    }

    // Development only
    private _checkGetProtocol(findings) {
        let found;
        this.getProtocolRequest.response.paragraphs.forEach(paragraph => {
            if (!found) {
                found = paragraph.children.find(child => this.paragraph.id === child.id);
            }
        });
        if (found) {
            // const arrHide: boolean[] = [];
            const questions: Question[] = [];
            Object.keys(findings).forEach(questionId => {
                questions.push(findings[questionId]);
                // arrHide.push(findings[questionId].hide);
            });
            // console.log(`${JSON.stringify(arrHide)} ${cn} getProtocol$`);

            // const a1 = JSON.stringify(arrHide);
            // const a2 = JSON.stringify(found.questions.map(question => question.hide));
            // console.log(`${a2} ${cn} getProtocol$ => ${a1 === a2 ? 'OK' : 'NOK'}`);
            this._compareQuestions(questions, found.questions, 'getProtocol$');
        } else {
            console.warn(`${cn} getProtocol$ cannot find paragraph with id='${this.paragraph.id}'`);
        }
        this.getProtocolRequest = null;
    }

    private _checkUpdateModel(findings) {
        const found = this.updateModelRequest.response.updated_paragraphs.find(
            paragraph => this.paragraph.id === paragraph.id
        );
        if (found) {
            // const arrHide: boolean[] = [];
            const questions: Question[] = [];
            Object.keys(findings).forEach(questionId => {
                questions.push(findings[questionId]);
                // arrHide.push(findings[questionId].hide);
            });
            // console.log(`${JSON.stringify(arrHide)} ${cn} updateModel$`);

            // const a1 = JSON.stringify(arrHide);
            // const a2 = JSON.stringify(found.questions.map(question => question.hide));
            // console.log(`${a2} ${cn} updateModel$ => ${a1 === a2 ? 'OK' : 'NOK'}`);
            this._compareQuestions(questions, found.questions, 'updateModel$');
        } else {
            console.warn(`${cn} updateModel$ cannot find paragraph with id='${this.paragraph.id}'`);
        }
        this.updateModelRequest = null;
    }

    // Development only
    private _compareQuestions(q1: Question[], q2: Question[], fn: string) {
        const _showDiffs = (q1, q2) => {
            const _diff = (d: Record<string, unknown>, s: string) => {
                let result = null;
                if (d[s]) {
                    const js = JSON.stringify(d[s]);
                    if (js !== '{}') {
                        result = `${s}: ${js}`;
                    }
                }
                return result;
            };
            const d = detailedDiff(q1, q2) as unknown as Record<string, unknown>;
            console.warn();
            return `{ ${['added', 'deleted', 'updated']
                .map(s => _diff(d, s))
                .filter(result => !!result)
                .join(', ')} }`;
        };

        const _q1 = _.cloneDeep(q1);
        _q1.sort((a, b) => (a.id < b.id ? -1 : b.id < a.id ? 1 : 0));
        const _q2 = _.cloneDeep(q2);
        _q2.sort((a, b) => (a.id < b.id ? -1 : b.id < a.id ? 1 : 0));

        _q1.forEach((q, index) => {
            const arr: Option[] = [];
            Object.keys(q.options).forEach(id => {
                arr.push(q.options[id]);
            });
            q.options = arr;
            q.options.sort((a, b) => (a.id < b.id ? -1 : b.id < a.id ? 1 : 0));
            _q2[index].options.sort((a, b) => (a.id < b.id ? -1 : b.id < a.id ? 1 : 0));
            if (JSON.stringify(diff(q, _q2[index])) !== '{}') {
                // console.log(`q1[${index}]`, q);
                // console.log(`q2[${index}]`, _q2[index]);
                console.warn(`${cn} ${fn} question[${index}] diffs='${_showDiffs(q, _q2[index])}'`);
            }
        });
    }
}
