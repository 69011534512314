
<div class="row force-fit" >
    <div class="layout">
        <!--    <div class="layout__page">-->
        <main id="content" class=""[ngClass]="{ waiting: loading }">

            <div class="page">
                <div class="" style="padding-top: 2em;">
                    <div *ngIf="loading" id="loading" style="display: block;">
                        <div class="spinner">
                            <div class="dot1"></div>
                            <div class="dot2"></div>
                        </div>
                    </div>
                    <ng-container *ngIf="!loading">
                        <ng-container *ngIf="literature?.error">
                            <ng-container *ngTemplateOutlet="showError">
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="literature && !literature.error">
                            <section class="page__section -no-bottom -transparent" style="padding-top: 0;">
                                <div class="row">
                                    <div class="col-md-8 pull-right px-10">
                                        <ng-container *ngTemplateOutlet="articleStatusHeader"></ng-container>
                                    </div>   
                                    <div class="col-md-3 pull-right px-10">
                                        <span
                                        *ngIf="getNextArticleId()"
                                            type="button"
                                            style="font-size: 70% !important; margin-left: 2px;"
                                            class="button -primary pull-right text-capitalize"
                                            [routerLink]="['/protocols', protocolId, 'literatures', getNextArticleId()]"
                                        >
                                            {{ 'LITERATURE.NEXT' | translate }}
                                        </span>
                                        <span
                                            *ngIf="getPreviousArticleId()"
                                            type="button"
                                            style="font-size: 70% !important;"
                                            class="button -primary pull-right text-capitalize"
                                            [routerLink]="['/protocols', protocolId, 'literatures', getPreviousArticleId()]"
                                        >
                                            {{ 'LITERATURE.PREVIOUS' | translate }}
                                        </span>
                                    </div>
                                    <div class="col-md-1 pull-right">
                                        <button
                                            type="button"
                                            class="close pull-right"
                                            aria-label="Close"
                                            [routerLink]="['/protocols', protocolId, 'literatures']"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </section>
                            <section class="page__section -no-bottom -transparent">                             
                                    <ng-container *ngTemplateOutlet="articleDetail"></ng-container>
                            </section>
                            <ng-container *ngIf="literature">
                                <ng-container *ngTemplateOutlet="evidenceTableList" ></ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </main>
    </div>
</div>

<ng-template #articleDetail>
    <section class="page__section -no-bottom">
        <div class="row">
            <div class="col" *ngIf="literature">
                <div>
                    <h5 class="wysiwyg">{{ literature.title }}</h5>
                </div>
                <div class="w-100"><hr /></div>
                <div>
                    <h6 class="wysiwyg">{{ literature.authors }}</h6>
                    <h6 class="wysiwyg">{{ literature.date }}</h6>
                </div>
                <div class="w-100"><hr /></div>

                <ng-container *ngIf="literature.intro">
                    <h3 class="wysiwyg">Abstract</h3>
                    <div class="text-justify wysiwyg user-text" [innerHTML]="literature.intro"></div>
                    <br />
                </ng-container>
                <span type="button" 
                    style="margin-bottom: 8px"
                    class="button -primary pull-right text-capitalize" 
                    (click)="onClickAddFile()">
                    <span
                        *ngIf="isUploading()"
                        class="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                        
                    ></span>
                    files ({{ literature.files?.length }})
                </span>

                <div *ngIf="canViewFullText()">
                    <span type="button" *ngIf="!literature.fullTextLocation"
                    style="margin-bottom: 8px; margin-right: 10px"
                    class="button -primary pull-right text-capitalize" 
                    (click)="onUploadFullText()">
                        <span
                            *ngIf="isUploadingToWebApi()"
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        Upload Full Text
                    </span>
                
                    <a [href]="literature.fullTextLocation" target="_blank"> 
                        <span type="button" *ngIf="literature.fullTextLocation"
                            style="margin-bottom: 8px; margin-right: 10px"
                            class="button -primary pull-right text-capitalize">
                            Download Full Text
                        </span>
                    </a> 
                </div>
            
                <ng-container *ngIf="literature.url">
                    <a [href]="literature.url" target="_blank">Open article <i class="fa fa-external-link"></i></a> 
                </ng-container>
            </div>
            <div class="col-md-6" *ngIf="showSecondCol()">
                <ng-container *ngIf="literature.votes?.length">
                    <ng-container *ngTemplateOutlet="votingStatus"></ng-container>
                </ng-container>
                <div class="row" *ngIf="literature.actions">
                    <div id="review_anchor"></div>
                    <div class="col">
                        <ng-container *ngTemplateOutlet="default"></ng-container>
                    </div>
                </div>
                <div class="row" class="w-100" id="reviewBox" [hidden]="!reviewBoxOpen" *ngIf="literature.actions">
                    <dynamic-form [config]="config" #form="dynamicForm" (submit)="onSubmitAction($event)"> </dynamic-form>
                </div>
                <div class="w-100"><hr /></div>
                <ng-container *ngTemplateOutlet="summaryOfFindingList"></ng-container>
            </div>
            
        </div>
    </section>
</ng-template>

<ng-template #votingStatus>
    <div class="votingStatus complete" *ngIf="literature.votingComplete">
        {{'LITERATURE.VOTINGCOMPLETE' | translate}}
    </div>
    <div class="votingStatus incomplete" *ngIf="literature.votingComplete == false">
        {{'LITERATURE.VOTINGINCOMPLETE' | translate}}
    </div>
    <div class="votingStatus complete" *ngIf="literature.ftvotingComplete">
        {{'LITERATURE.VOTINGCOMPLETE_FT' | translate}}
    </div>
    <div class="votingStatus incomplete" *ngIf="literature.ftvotingComplete == false">
        {{'LITERATURE.VOTINGINCOMPLETE_FT' | translate}}
    </div>
    <div class="wysiwyg" *ngIf="literature.votes">
        <ng-container *ngIf="!literature.isTwoStep">
            <h4>{{'LITERATURE.VOTINGHEADER' | translate}}</h4>
        </ng-container>
        <ng-container *ngIf="literature.isTwoStep">
            <h4>{{'LITERATURE.VOTINGHEADER_ABSTRACT' | translate}}</h4>
        </ng-container>
        <div class="wysiwyg" *ngFor="let vote of literature.votes">
            <p>
                {{vote.user}}
                <span class="pull-right">{{vote.date}} </span>
                <ng-container *ngIf="vote.update">
                    <span>{{'LITERATURE.UPDATEVOTE' | translate}}</span>
                </ng-container>
                <ng-container *ngIf="!vote.update">
                    <span>{{'LITERATURE.HASVOTED' | translate}}</span>
                </ng-container>
                <ng-container *ngIf="vote.vote == 'yes'">
                    <span style="text-decoration: underline;"> {{'LITERATURE.INCLUDE'  | translate}}</span>
                </ng-container>
                <ng-container *ngIf="vote.vote == 'no'">
                    <span style="text-decoration: underline;"> {{'LITERATURE.EXCLUDE' | translate}}</span>
                </ng-container>
                <ng-container *ngIf="vote.vote == 'discuss'">
                    <span style="text-decoration: underline;"> {{'LITERATURE.DISCUSS' | translate}}</span>
                </ng-container>
                <ng-container *ngIf="vote.vote == 'store'">
                    <span style="text-decoration: underline;"> {{'LITERATURE.STORE' | translate}}</span>
                </ng-container>
                <ng-container *ngIf="vote.comment">
                    <div [innerHTML]="vote.comment"></div>
                </ng-container>
            </p>
            <hr/>
        </div>
    </div>
    <div class="wysiwyg" *ngIf="literature.ftVotes && literature.ftVotes.length">
        <h4>{{'LITERATURE.VOTINGHEADER_FT' | translate}}</h4>

        <div class="wysiwyg" *ngFor="let vote of literature.ftVotes">
            <p>
                {{vote.user}}
                <span class="pull-right">{{vote.date}} </span>
                <ng-container *ngIf="vote.update">
                    <span>{{'LITERATURE.UPDATEVOTE' | translate}}</span>
                </ng-container>
                <ng-container *ngIf="!vote.update">
                    <span>{{'LITERATURE.HASVOTED' | translate}}</span>
                </ng-container>
                <ng-container *ngIf="vote.vote == 'yes'">
                    <span style="text-decoration: underline;"> {{'LITERATURE.INCLUDE'  | translate}}</span>
                </ng-container>
                <ng-container *ngIf="vote.vote == 'no'">
                    <span style="text-decoration: underline;"> {{'LITERATURE.EXCLUDE' | translate}}</span>
                </ng-container>
                <ng-container *ngIf="vote.vote == 'discuss'">
                    <span style="text-decoration: underline;"> {{'LITERATURE.DISCUSS' | translate}}</span>
                </ng-container>
                <ng-container *ngIf="vote.vote == 'store'">
                    <span style="text-decoration: underline;"> {{'LITERATURE.STORE' | translate}}</span>
                </ng-container>
                <ng-container *ngIf="vote.comment">
                    <div [innerHTML]="vote.comment"></div>
                </ng-container>
            </p>
            <hr/>
        </div>
    </div>
</ng-template>

<ng-template #articleStatusHeader>
    <div ngbDropdown class="d-inline-block" *ngIf="literature">
        <button class="btn stepsDropdown" id="dropdownMenu1" ngbDropdownToggle [innerHTML]="findStepTitle(literature?.category)"></button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenu1" ngbDropdownMenu>
            <button
                class="dropdown-item"
                [ngClass]="step.id === literature?.category ? 'selected' : 'not-selected'"
                *ngFor="let step of steps"
                (click)="onClickStepHeader(step.id)"
            >
                <span
                    [ngClass]="getHeaderStatusClass(step, literature) "
                    class="nav-link button text-lowercase"
                    *ngIf="step.showMenu"
                    [title]="step.title"
                >
                    {{ step.title }}  
                    <ng-container *ngIf="step.count > 0"> ({{step.count}})</ng-container>
                </span>
            </button>
        </div>
    </div>
</ng-template>



<ng-template #default>
    <div class="row" *ngIf="!!literature.inclusionCriteria">
        <div class="col">
            <button
                style="margin-bottom: 8px"
                class="button -primary pull-left text-capitalize" 
                (click)="openInclusionCriteriaModal()">                
                Inclusion criteria 
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <ng-container *ngIf="literature.status != 'new'">
                <h5 class="wysiwyg">{{'LITERATURE.ACTIONS' | translate}}</h5>
            </ng-container>
        </div>
    </div>
    <ng-container *ngIf="reviewBoxOpen && !startOpen">
        <a class="closeButton" (click)=closeReviewBox()>{{'CLOSE' | translate}}</a>
    </ng-container>
    <ng-container *ngIf="!reviewBoxOpen && !startOpen">
        <div class="wysiwyg miniHeader" *ngIf="getLiteratureActions('addReview').length">
            <p>{{'LITERATURE.REVIEWACTIONS' | translate}}</p>
        </div>
        <ng-container *ngFor="let action of getLiteratureActions('addReview'); trackBy: trackByFn">
            <div
                [hidden]="getArticleActionTitle()"
                *ngIf="!isComplete(action)"
                type="button"
                class="button -secondary"
                style="margin-bottom: 2px;"
                (click)="handleOpenForm(action)"
            >
                {{ action.label ? action.label : action.title }}
            </div>
            <div
                *ngIf="isComplete(action)"
                style="margin-bottom: 2px;"
                class="done"
            >
                <span  class="icon-check checkMark"></span>{{ action.label ? action.label : action.title }} 
            </div>
        </ng-container>
        <div class="wysiwyg miniHeader" *ngIf="getLiteratureActions('addAdditionalReview').length">
            <p>{{'LITERATURE.ADDITIONALREVIEWACTIONS' | translate}}</p>
        </div>
        <ng-container *ngFor="let action of getLiteratureActions('addAdditionalReview'); trackBy: trackByFn">
            <div
                [hidden]="getArticleActionTitle()"
                *ngIf="!isComplete(action)"
                type="button"
                class="button -secondary"
                style="margin-bottom: 2px;"
                (click)="handleOpenForm(action)"
            >
                {{ action.label ? action.label : action.title }} 
            </div>
            <div
                [hidden]="getArticleActionTitle()"
                *ngIf="isComplete(action)"
                type="button"
                class="button -primary"
                style="margin-bottom: 2px;"
            >
                {{ action.label ? action.label : action.title }}
            </div>
        </ng-container>
        <div class="wysiwyg miniHeader" *ngIf="getLiteratureActions('addFinding').length">
            <p>{{'LITERATURE.FINDINGACTIONS' | translate}}</p>
        </div>
        <ng-container *ngFor="let action of getLiteratureActions('addFinding'); trackBy: trackByFn">
            <div
                [hidden]="getArticleActionTitle()"
                type="button"
                class="button -secondary"
                style="margin-bottom: 2px;"
                (click)="handleOpenForm(action)"
            >
                {{ action.label ? action.label : action.title }}
            </div>
        </ng-container>
        <div class="wysiwyg miniHeader">
            <p>{{'LITERATURE.ACTIONSINTRO' | translate}}</p>
        </div>
        <ng-container *ngFor="let action of getLiteratureActions('vote'); trackBy: trackByFn">
            <div
                [hidden]="getArticleActionTitle()"
                type="button"
                class="button -secondary"
                style="margin-bottom: 2px;"
                (click)="handleOpenForm(action)"
            >
                {{ action.label ? action.label : action.title }}
            </div>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #evidenceTableList>
    <section 
        *ngIf="literature.reviews && reviewsToShow().length"
        class="page__section  -no-bottom -transparent"
    >
        <section class="page__section -no-bottom ">
            <div class="row">
                <div class="col"><h3 class="wysiwyg">{{'LITERATURE.EVIDENCETABLE' | translate }}</h3></div>
                <div class="col" *ngIf="literature.actions">
                    <ng-container *ngTemplateOutlet="evidenceTableActions"></ng-container>
                </div>
            </div>

            <ng-container *ngFor="let review of orderReviews(literature.reviews)">
                <ng-container *ngIf="!review.hide">

                    <div class="wysiwyg" *ngIf="review.subType">
                        <h4 *ngIf="review.subType === 'addReview'">
                            {{'LITERATURE.REVIEWHEADER' | translate}}
                        </h4>
                        <h4 *ngIf="review.subType === 'addGrade'">
                            {{'LITERATURE.REVIEWGRADEHEADER' | translate}}
                        </h4>
                        <h4 *ngIf="review.subType != 'addReview' && review.subType != 'addGrade'">
                            {{review.subType}}
                        </h4>
                    </div>
                    <div class="wysiwyg" *ngIf="!review.subType">
                        <h4>{{review.type}}</h4>
                    </div>
                    <p class="wysiwyg" *ngIf=review.user>{{review.user}}</p>
                    <alii-web-review-edit
                        *ngIf="review.rowData"
                        [rowData]="review.rowData"
                        [columnDefs]="review.columnDefs"
                        [reviewId]="review.id"
                        [protocolId]="protocolId"
                        (eventBus)="handleEventBus($event)"
                    ></alii-web-review-edit>    
                    <div class="w-100"><hr /></div>
                 </ng-container>
            </ng-container>
        </section>
    </section>
</ng-template>
<ng-template #evidenceTableActions>
    <ng-container   
        *ngIf="isActionsHasReviewType(literature.actions) && !isReviewsHasUpdateReviewAction(literature.reviews)"
    >
        <div
            *ngIf="literature.reviews && literature.reviews.length === 0"
            class="btn-group"
            ngbDropdown
            placement="bottom"
            style="float: right; margin-right:10px"
        >
            <span type="button" class="button" ngbDropdownToggle>Add evidence table</span>
            <ul class="dropdown-menu" ngbDropdownMenu>
                <ng-container *ngFor="let action of literature.actions; trackBy: trackByFn">
                    <li *ngIf="action.type === 'review'">
                        <a href-="#" class="dropdown-item" style="cursor: pointer;" (click)="handleOpenForm(action)">
                            {{ action.label ? action.label : action.title }}
                        </a>
                    </li>
                </ng-container>
            </ul>
        </div>
        <ng-container *ngTemplateOutlet="editEvidenceTableButton"></ng-container>
    </ng-container>
</ng-template>


<ng-template #summaryOfFindingList>
    <h5 class="wysiwyg" *ngIf="literature.findings && literature.findings.rowData">
        {{'LITERATURE.FINDINGS' | translate}}</h5>
    <alii-web-review-show
        *ngIf="literature.findings && literature.findings.rowData"
        [rowData]="literature.findings.rowData"
        [columnDefs]="literature.findings.columnDefs"
        [protocolId]="protocolId"
        (eventBus)="handleEventBus($event)"
        rowBased=true
        status="Current"
    ></alii-web-review-show> 

    <h5 class="wysiwyg" *ngIf="literature.findings_to_include && literature.findings_to_include.rowData">
        {{'LITERATURE.FINDINGSEDITABLE' | translate}}</h5>
    <alii-web-review-edit
        *ngIf="literature.findings_to_include && literature.findings_to_include.rowData"
        [rowData]="literature.findings_to_include.rowData"
        [columnDefs]="literature.findings_to_include.columnDefs"
        [protocolId]="protocolId"
        (eventBus)="handleEventBus($event)"
        rowBased=true
        status="Draft"
    ></alii-web-review-edit> 
</ng-template>



<ng-template #summaryOfFindingActions>
    <ng-container *ngIf="isActionsHasAddFindingType(literature.actions)">
        <ng-container *ngFor="let action of literature.actions; trackBy: trackByFn">
            <button
                *ngIf="action.title === 'addFinding'"
                type="button"
                class="button -primary pull-right text-capitalize"
                (click)="handleOpenForm(action)"
            >
                {{ action.label ? action.label : action.title }}
            </button>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #showError>
    <section class="page__section -no-bottom -transparent">
        <div class="row">
            <div class="col warning">
                <h4>
                    This article is not linked to this document
                </h4>
            </div>
        </div>
    </section>
</ng-template>
