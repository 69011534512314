import { FlowchartDeleteComponent } from './builder/flowchart-delete/flowchart-delete.component';
import { FlowchartStartComponent } from './builder/flowchart-start/flowchart-start.component';
import { FlowchartEditComponent } from './builder/flowchart-edit/flowchart-edit.component';
import { GradeFormModalComponent } from './grade-form/grade-form-modal.component';
import { UploadFileModalComponent } from './upload-file/upload-file.component';
import { SearchPubmedModalComponent } from './search-pubmed-modal/search-pubmed-modal.component';
import { InsertFootnoteModalComponent } from './insert-footnote-modal/insert-footnote-modal.component';
import { OrderingParModalComponent } from './odering-par/ordering-par-modal.component';
import { ModelHelpTextModalComponent } from './model-help-text/model-help-text.component';
import { OutcomeTreeviewModalComponent } from './outcome-treeview/outcome-treeview.component';
import { OutcomeEditModalComponent } from './outcome-edit/outcome-edit.component';
import { OutcomeTableModalComponent } from './outcome-table/outcome-table.component';
import { CompareParagraphsModalComponent } from './compare-paragraphs/compare-paragraphs.component';
import { CompareModelsModalComponent } from './compare-models/compare-models.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { LinkProtocolModalComponent } from './link-protocol-modal/link-protocol-modal.component';

import { FormSettingsComponent } from '../components/protocol-settings/form-settings/form-settings.component';
import { FormSearchTermComponent } from '../components/protocol-settings/form-search-term/form-search-term.component';
import { FormInclusionCriteriaComponent } from '../components/protocol-settings/form-inclusion-criteria/form-inclusion-criteria.component';

import { LiteratureImportModalComponent } from '../components/protocol-edit/paragraph-edit/literature-import-modal/literature-import-modal.component'
import { ConnectKeyQuestionModalComponent } from '../components/protocol-edit/paragraph-edit/connect-keyquestion-modal/connect-keyquestion-modal.component';
import { CommentAddModalComponent } from '../components/protocol-edit/paragraph-edit/footer-edit/comment-add-modal/comment-add-modal.component';

import { ViewArticleModalComponent } from '../components/protocol-edit/paragraph-edit/view-article-modal/view-article-modal.component';
import { UserFeedbackModalComponent } from '../components/protocol-detail/user-feedback-modal/user-feedback-modal.component';
import { SavePatientLinkModalComponent} from '../components/protocol-detail/save-patient-link-modal/save-patient-link-modal.component';

import { EditTagsModalComponent } from './edit-tags/edit-tags.component';

import { IframeModalComponent } from './iframe-modal/iframe-modal.component';

export const entryComponents = [
    FlowchartDeleteComponent,
    FlowchartStartComponent,
    FlowchartEditComponent,
    GradeFormModalComponent,
    UploadFileModalComponent,
    SearchPubmedModalComponent,
    InsertFootnoteModalComponent,
    OrderingParModalComponent,
    ModelHelpTextModalComponent,
    OutcomeTreeviewModalComponent,
    OutcomeEditModalComponent,
    CompareParagraphsModalComponent,
    CompareModelsModalComponent,
    ConfirmModalComponent,
    LinkProtocolModalComponent,
    FormSettingsComponent,
    FormSearchTermComponent,
    LiteratureImportModalComponent,
    OutcomeTableModalComponent,
    ConnectKeyQuestionModalComponent,
    CommentAddModalComponent,
    ViewArticleModalComponent,
    UserFeedbackModalComponent,
    SavePatientLinkModalComponent,
    EditTagsModalComponent,
    FormInclusionCriteriaComponent,
    IframeModalComponent
];

export * from './builder/flowchart-delete/flowchart-delete.component';
export * from './builder/flowchart-start/flowchart-start.component';
export * from './builder/flowchart-edit/flowchart-edit.component';
export * from './grade-form/grade-form-modal.component';
export * from './upload-file/upload-file.component';
export * from './search-pubmed-modal/search-pubmed-modal.component';
export * from './odering-par/ordering-par-modal.component';
export * from './model-help-text/model-help-text.component';
export * from './outcome-treeview/outcome-treeview.component';
export * from './outcome-edit/outcome-edit.component';
export * from './outcome-table/outcome-table.component';
export * from './compare-paragraphs/compare-paragraphs.component';
export * from './compare-models/compare-models.component';
export * from './confirm-modal/confirm-modal.component';
export * from './link-protocol-modal/link-protocol-modal.component';
export * from './edit-tags/edit-tags.component';
export * from '../components/protocol-settings/form-settings/form-settings.component';
export * from '../components/protocol-settings/form-search-term/form-search-term.component';
export * from '../components/protocol-settings/form-inclusion-criteria/form-inclusion-criteria.component';
export * from '../components/protocol-edit/paragraph-edit/connect-keyquestion-modal/connect-keyquestion-modal.component';
export * from './iframe-modal/iframe-modal.component';