export const en = {
    LOGIN: {
        TITLE: 'Login with an Alii acount',
        USERNAME: 'Username',
        PASSWORD: 'Password',
        'STAY-LOGGED-IN': 'Stay logged in',
        'FORGOT-PASSWORD': 'Forgot your password?',
        REGISTER: 'Would you like to register?',
        SSO: 'Login with an hospital account'
    },
    LOGOUT: {
        CONFIRM: {
            TITLE: 'Logout',
            TEXT: 'Are you sure that you want to logout?'
        }
    },
    LANGUAGE: {
        SWITCH: {
            EN: 'Switched to the English language',
            NL: 'Switched to the Dutch language'
        }
    },
    REGISTER: {
        TITLE: 'On this page you can register for the Alii Platform',
        FIRSTNAME: 'First name',
        LASTNAME: 'Last name',
        EMAIL: 'Email address',
        TELEPHONE: 'Telephone number (optional)',
        INSTITUTION: 'Institution',
        HOSPITAL: 'Hospital',
        INTEREST: 'Interest',
        'GOTO-LOGIN': 'Go to login page',
        TERMS: 'I accept the terms and conditions',
        'GOTO-TERMS': 'Go to terms and conditions page',
        LOGIN: 'Login'
    },
    FEEDBACK: {
        TITLE: 'We are eager to hear your feedback',
        MESSAGE: 'Message',
        NAME: 'Name',
        EMAIL: 'Email address',
        THANKS: 'Thanks for your message!'
    },
    DASHBOARD: {
        BANNER: {
            CLOSE: 'Close message'
        },
        CHOOSETEAM: {
            INTRO: 'Kies een van de volgende dashboards om mee te beginnen. Dit kun je later altijd wijzigen.'
        },
        MANAGE: {
            TITLE: 'Manage dashboards',
            EDIT_CATEGORY: 'Edit selected category',
            CREATE_CATEGORY: 'Create new category',
            DELETE_DASHBOARD: 'Remove dashboard',
            SUBTITLE: {
                LIST: 'List of available dashboards',
                UPDATE: 'Update an existing dashboard',
                CREATE: 'Create a new dashboard'
            },
            MAXLEN: 'Max. characters allowed',
            SELECT: 'Select a dashboard',
            CREATED: {
                OK: 'New dashboard has been created successfully',
                NOK: 'Cannot create dashboard'
            },
            DELETE: {
                TITLE: 'Delete dashboard',
                TEXT: 'Are you sure that you want to delete this dashboard?'
            },
            MANAGE: 'Manage',
            EXPLAIN_EDIT_CATEGORIES: 'Double click categories to add and remove them',
            SETTINGS: 'Settings',
            IS_EXCLUSIVE_TO_TEAM: "Is Dashboard exclusive to the current team?",
            'SELECT-CREATE': 'Create a new dashboard',
            ACTION: {
                LOAD: {
                    OK: 'Dashboard loaded successfully',
                    NOK: 'Cannot find dashboard, please select another one from the list'
                },
                CREATE: {
                    OK: 'New dashboard created successfully',
                    NOK: 'Cannot create new dashboard'
                },
                UPDATE: {
                    OK: 'Dashboard updated successfully',
                    NOK: 'Cannot update dashboard'
                },
                DELETE: {
                    OK: 'Dashboard deleted successfully',
                    NOK: 'Cannot delete dashboard'
                }
            },
            'SUBITEM-LIST': {
                SELECTED: 'Selected categories',
                AVAILABLE: 'Available categories'
            },
            TOOLTIP: {
                UPDATE: 'Save changes to this dashboard',
                RESET: 'Restore original values of this dashboard',
                DELETE: 'Delete this dashboard!',
                CREATE: 'Create a new category',
                MANAGE: 'Go to the categories management page'
            }
        },
        ACTIONS: {
            SELECT: {
                TITLE: 'Dashboard',
                TOOLTIP: 'Select another dashboard'
            },
            MANAGE: {
                TITLE: 'Manage',
                'DOCUMENT-CREATE': 'Create new document',
                'DOCUMENT-IMPORT': 'Import document',
                'DASHBOARDS-MANAGE': 'Dashboards',
                'DASHBOARD-EDIT': 'Current dashboard',
                'CATEGORIES-MANAGE': 'Categories'
            }
        },
        PATIENTS: {
            TITLE: 'My patients',
            'ADD-NEW-CASE': 'Add a new case',
            'ADD-EXISTING-CASE': 'Add an existing case',
            DELETE: {
                TITLE: 'Delete patient',
                TEXT: 'Are you sure you want to delete this patient?'
            }
        }
    },
    _CATEGORY: {
        MANAGE: {
            TITLE: 'Manage categories',
            SUBTITLE: {
                LIST: 'List of available categories',
                UPDATE: 'Update an existing category',
                CREATE: 'Create a new category'
            },
            MAXLEN: 'Max. characters allowed',
            SELECT: 'Select a category',
            CREATED: {
                OK: 'New category has been created successfully',
                NOK: 'Cannot create category'
            },
            DELETE: {
                TITLE: 'Delete category',
                TEXT: 'Are you sure that you want to delete this category?'
            },
            MANAGE: 'View protocol',
            'SELECT-CREATE': 'Create a new category',
            ACTION: {
                LOAD: {
                    OK: 'Category loaded successfully',
                    NOK: 'Cannot find category, please select another one from the list'
                },
                CREATE: {
                    OK: 'New category created successfully',
                    NOK: 'Cannot create new category'
                },
                UPDATE: {
                    OK: 'Category updated successfully',
                    NOK: 'Cannot update category'
                },
                DELETE: {
                    OK: 'Category deleted successfully',
                    NOK: 'Cannot delete category'
                }
            },
            'SUBITEM-LIST': {
                SELECTED: 'Selected protocols',
                AVAILABLE: 'Available protocols'
            },
            TOOLTIP: {
                UPDATE: 'Save changes to this category',
                RESET: 'Restore original values of this category',
                DELETE: 'Delete this category!',
                MANAGE: 'Go to the selected protocol page',
                DASHBOARD: 'Go to the dashboard management page'
            },
            LISTITEM: 'Category',
            LISTITEMS: 'Categories',
            SUBITEM: 'Protocol',
            SUBITEMS: 'Protocols'
        }
    },
    PROTOCOLS: {
        CREATE: {
            'PASTE-CONTENT': 'Paste content',
            HELP:
                // eslint-disable-next-line max-len
                'Start from an existing document|If you have an existing document you can copy its content, paste it below and it will used as the starting point'
        },
        IMPORT: {
            PLACEHOLDER: 'Search for document'
        },
        DELETE: {
            TITLE: 'Delete document',
            TEXT: 'Are you sure that you want to delete this document?'
        },
        MARGIN: {
            'OLDER-VERSIONS': 'Older versions',
            BUTTON: {
                'TEXT': 'Text',
                'FLOW': 'Flow',
                'INTERACTIVE': 'Interactive',
                'CONTENT': 'Text',
                'REVIEW-DRAFT': 'Review draft',
                'REVIEW-LIT': 'Literature',
                SETTINGS: 'Settings',
                'CURRENT-VERSION': 'Current version',
                DRAFT: 'Draft',
                'CURRENT-DRAFT-VERSION': 'Current draft version',
                'CREATE-DRAFT': 'Create draft'
            }
        }
    },
    SETTINGS: {
        'TITLE': 'Title',
        'LITERATURE': 'Manage literature',
        'FLOWCHART': 'Show flowchart (when present)',
        'INTERACTIVE': 'Show Interactive mode',
        'ALLTHETEAMMEMBERS': 'Alle leden van mijn organisatie',
        'AUTHORSONLY': 'Alleen de auteurs',
        'TRUE': 'Yes',
        'FALSE': 'No',
        'OWNER': 'Owners:',
        'PROTOCOLREVIEWBOARD': 'Reviewers of this document:',
        'TEAMREVIEWBOARD': 'Review board:',
        'PROTOCOLSUBSCRIBERS': 'Subscribed to changes:',
        'AUTHORS': 'Co-authors:',
        'REMOVEAUTHOR': 'Remove',
        'SETOWNER': 'Set as owner',
        'SETREVIEWER': 'Set as reviewer',
        'ADDUSER': 'Add user',
        'SELECT': 'Select',
        'ADDUSERWITHNAME': 'Add:',
        'DELETE': 'Remove document',
        'DELETEWARNING': 'Are you sure you want to remove this document?',
        USERS: {
            'HEADER': 'Current roles',
            'INTRO': 'These are the current roles for this document. Owners and authors can edit the document. Reviewers decide if a new version can be published. Subscribers are notified when there is a new version.',
            'ADDAUTHOR': 'Set as author',
            'ADDREVIEWER': 'Set as reviewer',
            'ADDNOTIFIEDUSERS': 'Set as subscriber'
        }
    },
    FOOTER: {
        FILES: 'files',
        COMMENTS: 'comments',
        REFERENCES: 'references',
        KEYQUESTION: 'key questions'
    },
    GRADEREVIEW: {
        SUPPORTING_EVIDENCE: 'Supporting Evidence',
        DOCUMENTATION: 'Documentation',
        COMMENT: 'Comment (optional)',
        CHOOSE_ANSWER: 'Your answer',
        WAITING: 'The new file will appear here',
        ADD_DOCUMENTATION: 'Add a file',
        CHANGE_DOCUMENTATION: 'Change file',
    },
    LITERATURE: {
        ADDREFERENCEMODAL: {
            'TITLE': 'Add references',
            'TAGLINESEARCH': 'Search for articles to include',
            'TAGLINESELECT': 'Select one or more sources',
        },
        PARAGRAPH: {
            'GRADE' : 'Grade assesment',
            'NOREFERENCES': 'No articles found',
            'REFERENCES': 'References',
            'SOURCES': 'Sources',
        },
        REVIEW: {
            'DELETEROW': 'Delete row(s)',
            'EDITROW': 'Create draft',
            'DUPLICATEROW': 'Copy row(s)',
            'CHANGEKEYQUESTION': 'Change PICO'
        },
        FINDINGS: 'Findings included in a published document',
        FINDINGSEDITABLE: 'In a draft version',
        FINDINGSTOINCLUDE: 'Findings not included in conclusions',
        SUPPORTING_ARTICLES: 'Supporting articles',
        SUMMARYOFFINDINGS: 'Summary of findings',
        EVIDENCETABLE: 'Evidence table',
        REVIEWACTIONS: 'Perform data extraction',
        REVIEWHEADER: 'General study and patient characteristics',
        REVIEWGRADEHEADER: 'General quality characteristics',
        ADDITIONALREVIEWACTIONS: 'Perform specific data extraction for at least one of the following',
        FINDINGACTIONS: 'Add relevant study outcomes to the summary of findings table',
        NEXT: 'Next article',
        PREVIOUS: 'Prevous article',
        HASVOTED: 'has voted:',
        UPDATEVOTE: 'has update their vote to:',
        INCLUDE: 'Include',
        EXCLUDE: 'Exclude',
        DISCUSS: 'Discuss',
        STORE: 'Save for  later',
        NOARTICLES: 'There are no new articles that match your criteria. You are all done!',
        VOTINGCOMPLETE: 'The abstract has been reviewed',
        VOTINGINCOMPLETE: 'Not enough reviewers have voted on this article. You can add a review and finding while you wait for other reviewers to vote.',
        VOTINGHEADER: 'Votes',
        VOTINGHEADER_ABSTRACT: 'Votes based on abstract',
        VOTINGCOMPLETE_FT: 'Voting based on full text is complete',
        VOTINGINCOMPLETE_FT: 'Not enough reviewers have voted on the full text of the article.',
        VOTINGHEADER_FT: 'Votes based on full text',
        ACTIONS: 'Actions',
        ACTIONSINTRO: 'Inclusion',
        OPEN_PROTOCOL: 'Back to the protocol',
        SOURCELIST: 'Sources',
        ADD_BYHAND: 'Add article by hand',
        ADD_PUBMED: 'Add article from PubMed',
        ADD_RIS_ARTICLE: 'Add article from RIS file',
        SOURCESSELECTED: 'sources selected',
        SEARCHSTRATEGY_HEADER: 'Search strategy',
        SEARCHSTRATEGY_TEXT: 'The following search term was used',
        SEARCHSTRATEGY_RESULTS_TOTAL: 'Number of results',
        SEARCHSTRATEGY_RESULTS_EXCLUDED: 'Excluded',
        SEARCHSTRATEGY_RESULTS_INCLUDED: 'Included'
    },
    PARAGRAPH: {
        CONCLUSION: 'Conclusion',
        RECOMMENDATION: 'Recommendation',
        CONSIDERATION: 'Consideration',
        CONCLUSIONS: 'Conclusions',
        RECOMMENDATIONS: 'Recommendations',
        CONSIDERATIONS: 'Considerations',
        EVIDENCE: {
            HIGHQUALITY: 'High quality evidence',
            MODERATEQUALITY: 'Moderate quality evidence',
            LOWQUALITY: 'Low quality evidence',
            VERYLOWQUALITY: 'Very low quality evidence',
        },
        EDIT: {
            ADDFILE: 'Add file',
            EDITTAGS: 'Edit tags',
            WARNINGUPDATEKEYQUESTION: 'A new version of the key question was published. Press update to fetch the latest version.',
            UPDATEKEYQUESTION: 'Update',
            CHANGEKEYQUESTION: 'Connect to a different key question',
            CONNECTKEYQUESTION: 'Connect to key question',
            IMPORTREFERENCE: 'Import references',
            ADDREFERENCE: 'Add reference',
            SELECTREFERENCE: 'Assign articles to conclusion',
            LINKTOPROTOCOL: 'Link to protocol',
            REMOVELINKTOPROTOCOL: 'Remove Link to protocol',
            ADDGRADE: 'Grade the evidence',
            DELETEGRADE: 'Remove Grade review',
            DELETE: 'Delete',
            ADDCHAPTER: 'Add chapter',
            ADDRECOMMENDATION: 'Add recommendation',
            ADDCONSIDERATION: 'Add consideration',
            ADDCONCLUSION: 'Add conclusion',
            ADDPARAGRAPH: 'Add paragraph',
            CREATEMODELFROMSHEET: 'Create model from sheet',
            IMPORTEVIDENCIOMODEL: 'Import evidencio model',
            CONVERTKEYQUESTION: 'Convert paragraph into Key Question',
            UPDATED: 'Compare with published version',
            NEW: 'New',
            RECENTLYUPDATED: 'Compare with previous draft',
            COMPAREMODEL: 'Model updated'
        }
    },
    SUMMARY: {
        CONVERT: 'Convert to summary',
        CONVERTBACK: 'Convert to paragraph'
    },
    MODEL: {
        EDIT: {
            UPDATESHEET: 'Update model from sheet',
            UPDATELITERATURE: 'Update Literature from sheet',
            CREATECALCULATION: 'Create calculation formula',
            MODELEDITOR: 'Edit in new model editor'
        },
        DETAIL: {
            TITLE: 'No outcomes',
            TEXT: 'Select answers to the questions to the left in order to see relevant outcomes',
            INFORMATION: 'More information',
            RESET: 'New case',
            SHOWALL: 'Show all outcomes',
            SHOWSELECTED: 'This case',
            NOFILTERS: 'No filters',
            SELECT: {
                SINGLE: 'Select',
                MULTIPLE: 'Select 1 or more options'
            },
            TREEVIEW: 'Show more information'
        },
        OUTCOME: {
            QUALITY_EVIDENCE: 'Quality of evidence',
            QUALITY_EVIDENCE_LEVEL_1: 'strength recommendation: strongly against',
            QUALITY_EVIDENCE_LEVEL_2: 'strength recommendation: conditionally against',
            QUALITY_EVIDENCE_LEVEL_3: 'strength recommendation: conditionally in favor',
            QUALITY_EVIDENCE_LEVEL_4: 'strength recommendation: strongly in favor',
            SOURCE_EVIDENCE: 'Source',
            EDIT: {
                MODALTITLE: 'Report',
                MODALINTRO: 'You can add your own text for this outcome. This text will be used for the summary.'
            },
            TREEVIEW: {
                TITLE: 'Outcome treeview',
                TEXT: 'This outcome is valid for the following (combinations of) answers',
                MODALTITLE: 'Overview of relevant situations',
                HEADERCONCLUSION: 'Scientific basis',
                CONCLUSIONINTRO: 'This recommendation is based on the following key question.',
                MODALTEXT: 'The outcome is shown in the following situations',
                BACK: 'Back',
                SHOWSOURCES: 'Show sources',
                CAPTION: {
                    0: 'In this document',
                    1: 'In source document'
                }
            }
        }
    },
    COMMENT: {
        MARKASDONE: 'Resolve',
        REOPEN: 'Reopen',
        REPLIES: 'Replies',
        REPLY: 'Add reply',
        DONE: 'Closed'
    },
    HELP: {
        TITLE: 'Help',
        MODAL: 'Support form',
        PROBLEM: 'Report a problem'
    },
    TERMS: {
        TITLE: 'Terms and conditions',
        CONFIRMATION: 'I have read the terms and accept them as stated above',
        'ACCEPTED-ON': 'Accepted on',
        THANKS: 'Thank you for accepting the terms and conditions'
    },
    'TEXT-EDIT': {
        'COPY-LINK': 'Copy link',
        'COPY-TEXT': 'Copy text',
        'COPY-TEXT-NO-FORMATTING': 'Copy text without formatting',
        'OPEN-DOCUMENT': 'Open document',
        'LINK-COPIED': 'Link copied to clipboard',
        'TEXT-COPIED': 'Text copied to clipboard',
        'CLICK-TO-EDIT': 'Click to edit'
    },
    'NOT-FOUND': {
        HEADING: 'Not found',
        SUBHEADING: 'Sorry, but this page does not exist',
        HOME: 'Back to home page'
    },
    INFOBOX: {
        ORIGINAL: {
            HEADER: 'Source document',
            INTRO: 'This document is based on',
            DATE: 'Last update'
        },
        ACTIONS: {
            REJECT_REASON: 'Give a reason why you want to reject the protocol'
        }
    },
    NAVIGATION: {
        SEARCH: 'Search',
        HOME: 'Home',
        TEAM: 'Team',
        MESSAGES: 'Messages',
        AGENDA: 'Agenda',
        LIBRARY: 'Library',
        PROFILE: 'Profile',
        HELP: 'Help',
        LOGOUT: 'Logout',
        LANGUAGE: 'Language',
        COLLAPSE: 'Collapse',
        EXPAND: 'Expand'
    },
    AUTHORS: 'Authors',
    AVAILABLE: 'Available',
    BACK: 'Back',
    REPLY: 'Reply',
    CANCEL: 'Cancel',
    CASE: 'Case',
    CATEGORY: 'Category',
    CATEGORIES: 'Categories',
    CONFIRM: 'Confirm',
    COPY: 'Copy',
    CREATE: 'Create',
    CURRENT: 'Current',
    'DASHBOARD-MANAGEMENT': 'Dashboard',
    DELETE: 'Delete',
    GUIDELINE: 'Guideline',
    DOCUMENT: 'Document',
    DOCUMENTS: 'Documents',
    DRAFT: 'Draft',
    SETTINGS_VERSION: 'Settings',
    LITERATURE_VERSION: 'Literature',
    DUTCH: 'Dutch',
    EDIT: 'Edit',
    EN: 'English',
    ENGLISH: 'English',
    ERROR: 'Error',
    EXISTING: 'Existing',
    FILTER: 'Filter',
    LOADING: 'Loading',
    MANAGE: 'Manage',
    'MORE-INFORMATION': 'More information',
    NAME: 'Name',
    NL: 'Dutch',
    NO: 'No',
    'NO-RESULTS': 'No results',
    NONE: 'None',
    OK: 'Ok',
    'OPEN LINK': 'Open Link',
    OPTIONS: 'Options',
    PAGE: 'Page',
    'PAGE-SIZE': 'Page size',
    PREVIEW: 'Preview',
    READMORE: 'Read more',
    REMOVE: 'Remove',
    RESET: 'Reset',
    SAVE: 'Save',
    SEARCH: 'Search',
    SEARCHTEXT: 'Full results',
    SEARCHTITLE: 'Open search page',
    SELECT: 'Select',
    SIGNIN: 'Login',
    SIGNOUT: 'Logout',
    SIGNUP: 'Register',
    'SINGLE-SIGNON': 'Single Sign-on',
    SUBMIT: 'Submit',
    SUCCESS: 'Success',
    TITLE: 'Title',
    TYPE: 'Type',
    'TYPE-SOMETHING': 'Type something',
    UPDATE: 'Update',
    VERSIONS: 'Versions',
    VERSION: {
        COMPARE: 'Compare with previous version',
        OLDERVERSIONS: 'Previous versions',
        STOREDDRAFTVERSIONS: 'Stored drafts'
    },
    YES: 'Yes',
    TAP_TO_CHANGE_TITLE: 'Tap to change title',
    PLEASE_SELECT: 'selecteer',
    CLOSE: 'close'
};
