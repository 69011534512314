import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { Literatures } from '../models/literature-collection.interface';
import { Pubmed } from '../models/pubmed.interface';

@Injectable()
export class LiteraturesService {
    constructor(private http: HttpClient) {}
    uploadFullTextArticle(payload): Observable<any> {
        const { formData, articleId } = payload;
        const uploadURL = `${environment.baseUrl}/api/protocol/article/uploadFullText.vm/${articleId}`
        return this.http
            .post<any>(uploadURL, formData)
            .pipe(
                map(response => response),
                catchError((error: any) => throwError(error))
            );
    }
    getLiteratures(payload): Observable<Literatures> {
        const { protocolId } = payload;
        const url = `${environment.baseUrl}/api/protocol/getLiterature.vm?protocolId=${protocolId}`;
        return this.http.get<any>(url).pipe(
            map(response => {
                const { steps, paragraphs, populations, versions, protocolDetails } = response;
                const evidenceAction =
                    'articles_add_evidence' in response && response['articles_add_evidence'].actions
                        ? response['articles_add_evidence'].actions
                        : [];

                const { stepsData, literatures } = steps.reduce(
                    (acc, step) => {
                        const entries = response[step.id][step.id].map(entry => ({
                            ...entry,
                            category: step.id,
                            actions: response[step.id].actions,
                            evidenceAction
                        }));
                        return {
                            stepsData: {
                                ...acc.stepsData,
                                [step.id]: {
                                    label: step.title,
                                    id: step.id,
                                    rowData: response[step.id].rowData,
                                    columnDefs: response[step.id].columnDefs,
                                    entries,
                                    actions: response[step.id].actions
                                }
                            },
                            literatures: [...acc.literatures, ...entries]
                        };
                    },
                    { stepsData: {}, literatures: [] }
                );

                return { steps, data: stepsData, literatures, paragraphs, populations, versions, protocolDetails };
            }),
            catchError((error: any) => 
                throwError(error))
        );
    }
    getLiterature(payload): Observable<any> {
        const { protocolId, literatureId } = payload;
        const url = `${environment.baseUrl}/api/protocol/article/getArticle.vm?articleId=${literatureId}&protocolId=${protocolId}&response=message`;
        return this.http.get<any>(url).pipe(
            map(response => {
                return response;
            }),
            catchError((error: any) => throwError(error))
        );
    }
    findLiterature(payload) {
        const { response, literatureId } = payload;
        const { steps } = response;

        const { literatures } = steps.reduce(
            (acc, step) => {
                const entries = response[step.id][step.id].map(entry => ({
                    ...entry,
                    category: step.id,
                    actions: response[step.id].actions
                }));
                return {
                    literatures: [...acc.literatures, ...entries]
                };
            },
            { stepsData: {}, literatures: [] }
        );

        const literature = literatures.find(entry => entry.id + '' === literatureId);

        return { literature, literatureId };
    }

    searchArticles(query: string): Observable<Pubmed[]> {
        const url = `${environment.baseUrl}/api/protocol/article/search.vm?query=${query}`;
        return this.http.get<any>(url).pipe(
            map(response => {
                const { esummaryresult } = response;
                if (esummaryresult) {
                    const pubmeds: any[] = Object.values([]);
                    return pubmeds;
                } else {
                    const {
                        result: { uids, ...ids }
                    } = response;
                    const pubmeds: any[] = esummaryresult ? Object.values([]) : Object.values(ids);
                    return pubmeds;
                }
            }),
            catchError((error: any) => throwError(error))
        );
    }

    addNonPubmedArticle(payload) {
        const { protocolId, url, publication, date, author, title } = payload;
        const data = {
            protocolId,
            title,
            url,
            publication,
            date,
            author
        };
        const endPointUrl = `${environment.baseUrl}/api/protocol/article/addArticle.vm`;

        return this.http.post<any>(endPointUrl, data).pipe(
            map(response => {
                return {};
            }),
            catchError((error: any) => throwError(error))
        );
    }

    addArticle(payload) {
        const { protocolId, articleId } = payload;
        const data = {
            protocolId,
            articleId
        };
        const url = `${environment.baseUrl}/api/protocol/article/addArticle.vm`;
        return this.http.post<any>(url, data).pipe(
            map(response => {
                return {};
            }),
            catchError((error: any) => throwError(error))
        );
    }

    updateFindings(payload) {
        const url = `${environment.baseUrl}/api/protocol/par/updateFindings.vm?app=web`;
        return this.http.post<any>(url, payload).pipe(
            map(response => {
                const {
                    'message list': { message }
                } = response;
            }),
            catchError((error: any) => throwError(error))
        );
    }

    updateReview(payload) {
        const url = `${environment.baseUrl}/api/protocol/article/saveReview.vm?app=web`;
        let { articleId, protocolId, values, action, reviewId, paragraphId, populationId, findingIds } = payload;
        let paragraphIds = []
        
        //console.log(values)    
        
        if (values && values.paragraphId) {
             values.paragraphId.forEach(element => {
                paragraphIds.push(element)
            });
        }

        let data = undefined
        if(values) {
            data = JSON.parse(JSON.stringify(values));
            if(data.paragraphId) {
                delete data.paragraphId
            }
        }

        if(data && data.findingId) {
            if(!findingIds) { findingIds = []}
            findingIds.push(data.findingId)
            delete data.findingId
        }

        
        const postData = {
            articleId,
            protocolId,
            paragraphId,
            populationId,
            action,
            reviewId,
            paragraphIds,
            data,
            findingIds
        };
        return this.http.post<any>(url, postData).pipe(
            map(response => {
                const {
                    'message list': { articles }
                } = response;

                return articles[0];
            }),
            catchError((error: any) => throwError(error))
        );
    }
    getGradeAssessment(payload): Observable<any> {
        const { gradeId, protocolId, ppdId, gradeType = 'ppd' } = payload;
        let url = `${environment.baseUrl}/api/protocol/par/getGradeAssessment.vm?gradeId=${gradeId}&protocolId=${protocolId}`;

        if (gradeType === 'population') {
            url += `&populationId=${ppdId}`;
        } else {
            url += `&ppdId=${ppdId}`;
        }

        return this.http.get<any>(url).pipe(
            map(response => {
                return response;
            }),
            catchError((error: any) => throwError(error))
        );
    }
    createGradeValue(payload): Observable<any> {
        const { protocolId, ppdId, key, value, type, comment } = payload;

        const data = {
            action: 'addGradeAssessment',
            question: key,
            answer: value,
            ppdId,
            protocolId,
            comment
        };
        const url = `${environment.baseUrl}/api/protocol/par/saveGradeAssessment.vm`;
        return this.http.post<any>(url, data).pipe(
            map(response => {
                return response;
            }),
            catchError((error: any) => throwError(error))
        );
    }
    updateGradeValue(payload): Observable<any> {
        const { protocolId, gradeId, key, value, values, ppdId, comment } = payload;
        const data = {
            action: 'editGradeAssessment',
            protocolId,
            gradeId,
            values,
            ppdId,
            comment,
            question: key,
            answer: value,
            [key]: value
        };
        const url = `${environment.baseUrl}/api/protocol/par/saveGradeAssessment.vm`;
        return this.http.post<any>(url, data).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }

    deleteGrade(payload): Observable<any> {
        const { protocolId, ppdId, parentId } = payload;
        const data = {
            action: 'deleteGradeAssessment',
            protocolId,
            ppdId,
            parentId
        };
        const url = `${environment.baseUrl}/api/protocol/par/saveGradeAssessment.vm`;
        return this.http.post<any>(url, data).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }

    updateGradeRow(payload): Observable<any> {
        const { articleId, reviewId, question, answer, parentQuestion, ppdId, sourceId } = payload;
        const data = {
            action: 'editGradeRow',
            gradeId: reviewId,
            question,
            answer,
            sourceId,
            ppdId,
            parentQuestion,
            articleId
        };
        const url = `${environment.baseUrl}/api/protocol/par/saveGradeAssessment.vm`;
        return this.http.post<any>(url, data).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }
}
