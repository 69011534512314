import {
    Component,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    OnInit,
    SimpleChanges,
    OnChanges,
    ChangeDetectorRef
} from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'apps/web/src/app/services';
import { Literature } from '../../../../models/literature.interface';
import { FieldConfig } from '../../../../models/field-config.interface';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
import { UploadFileModalComponent } from '../../entry-components/upload-file/upload-file.component';
import { ModalInclusionCriteriaComponent } from './inclusion-criteria/modal-inclusion-criteria.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'literatures-detail',
    templateUrl: './literatures-detail.component.html',
    styleUrls: ['./literatures-detail.component.scss']
})
export class LiteraturesDetailComponent implements OnInit, OnChanges {
    @ViewChild(DynamicFormComponent) form: DynamicFormComponent;
    @Input() ids: any[];
    @Input() protocolId: string;
    @Input() protocol: any;
    @Input() literatureId: string;
    @Input() steps: any[];
    @Input() literature: Literature;
    @Input() paragraphs: any[];
    @Input() versions: any[];
    @Input() populations: any[];
    @Input() questions: any[] = [];
    @Input() loading: boolean;
    @Input() uploadEvent: any;
    reviewBoxOpen: boolean;
    startOpen: boolean;
    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    config: FieldConfig[] = [];
    regex = /_/gi;

    actionForm: FormGroup;

    constructor(private userService: UserService, private ref: ChangeDetectorRef, private fb: FormBuilder, private modalService: NgbModal) {}

    ngOnInit(): void {
        const action = {
            type: 'handleGetArticle',
            payload: {
                literatureId: this.literatureId,
                protocolId: this.protocolId,
            }
        };
        this.eventBus.emit(action);

        this.actionForm = this.fb.group({
            protocolId: [this.protocolId, Validators.required],
            articleId: [this.literatureId, Validators.required]
        });

    }

    reviewsToShow() {
        return this.literature.reviews.filter(e => !e.hide);
    }

    findStepTitle(id) {
        let step = this.steps.find(element => element.id == id)
        if(step) {
            return step.title + ' ' + '(' + this.getStepIndex(step) + '/' + step.count + ')'}
        return 'select'
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { literature } = changes;
        if (literature) {
            const { currentValue: literatureChange } = literature;
            if (literature && literatureChange && literatureChange.category && literatureChange.actions) {
                literatureChange.actions.forEach(action => {
                    if (action.startOpen) {
                        this.startOpen = true
                        setTimeout(() => {
                            this.ref.markForCheck();
                            this.handleOpenForm(action);
                        });
                    }
                });
            }
        }
    }

    isUploading() {
        return this.uploadEvent.isUploading;
    }

    isUploadingToWebApi() {
        return this.uploadEvent.isUploadingToWebApi;
    }

    closeReviewBox() {
        this.reviewBoxOpen = false
    }

    isComplete(action) {
        let isComplete = false
            if(this.literature.reviewComplete) {
            this.literature.reviewComplete.forEach(element => {
                if(element == action.title) {
                    isComplete = true;
                }
            });
        }
        return isComplete;
    }

    getArticleActionTitle() {
        const startOpenAction =
            this.literature && this.literature.actions
                ? this.literature.actions.find(action => action.startOpen)
                : false;
        return startOpenAction ? startOpenAction.label : false;
    }

    getLiteratureActions(type) {
        if (this.literature && this.literature.actions) {
            var actions = []
            this.literature.actions.forEach(element => {
                if (element.type == type ) {
                    actions.push(element)
                    }
            });
            if (this.literature.emptyFindings?.length > 0) {
                this.literature.emptyFindings.forEach(element => {
                    actions.push(this.getEmptyFindingAction(element))
                });
            }
            return actions;
        }
        return [];
    }

    getEmptyFindingAction(emptyFinding) {
        let Action = {
            title: 'updateEmptyFinding',
            label: 'Add finding to ' + emptyFinding.paragraphTitle,
            type: 'updateEmptyFinding',
            fields: [{
                        value: emptyFinding.id,
                        name: 'findingId',
                        field: 'findingId',
                        type: 'hidden'
                    },
                    {
                        value: 'field',
                        field: 'field',
                        name: 'fieldName',
                        type: 'number'
                    },
                    {
                        value: 'value',
                        field: 'value',
                        name: 'value',
                        type: 'number'
                    }],
            optional_fields: [],
            startOpen: false,
            moveToNextArticle: false
        }
    return Action
    }

    getHeaderStatusClass(step, literature) {
        const { category } = literature;
        if (!category) {
            return '';
        }
        if (step.count === 0) {
            return 'inActive';
        } else if (step.header === category) {
            return '-primary';
        }
        return '-secondary';
    }

    transform(input: string): any {
        if (!input) {
            return '';
        }
        input = input.replace(/&lt;span class='article-abstract'&gt;/g, '');
        input = input.replace(/&lt;\/span&gt;/g, '');
        return input;
    }

    getNextArticleId() {
        const nowIndex = this.ids.indexOf(this.literatureId);
        return nowIndex + 1 === this.ids.length ? false : this.ids[nowIndex + 1];

    }

    getStepIndex(step) {
        let count = 0
        let totalCount = 0
        this.steps.forEach(element => {
           if (element.id ===  step.id) {
                count = totalCount
            }
            totalCount = totalCount + element.count
        });
        return 1 + this.ids.indexOf(this.literatureId) - count
    }

    getPreviousArticleId() {
        const nowIndex = this.ids.indexOf(this.literatureId);
        return nowIndex === 0 ? false : this.ids[nowIndex - 1];
    }

    isActionsHasReviewType(actions: any[]) {
        return actions.some(e => e.type === 'review');
    }

    isReviewsHasUpdateReviewAction(reviews: any[]) {
        return reviews.some(e => e.action === 'updateReview');
    }

    isActionsHasAddFindingType(actions: any[]) {
        return actions.some(e => e.type === 'addFinding');
    }

    handleEventBus(event) {
            const { type, payload } = event;
            switch (type) {
                case 'connectToDifferentPico':
                    this.handleOpenChangePICOForm(payload);
                    break;
                default:
                    this.eventBus.emit(event);;
            }
    }

    onSubmitAction(value: { [name: string]: any }) {
        this.reviewBoxOpen = false;
        const { moveToNextArticle, isVote, ...values } = value;
        const eventPayload = {
            type: 'handleLiteratureSubmitAction',
            payload: {
                action: values.action,
                values,
                nextArticleId: moveToNextArticle ? this.getNextArticleId() : false,
                isVote
            }
        };
        this.eventBus.emit(eventPayload);
        this.config = [];
    }

    formatEvidenceTableLabel(fieldname) {
        const label = fieldname.replace(/_/g, ' ');
        return label.charAt(0).toUpperCase() + label.slice(1);
    }

    getEvidenceTableLabel(fieldName, evidenceActionType) {
        if ('evidenceAction' in this.literature) {
            const action = this.literature.evidenceAction.find(a => a.title === evidenceActionType);
            if (action) {
                const field = action['fields'].find(actionField => actionField.field === fieldName);
                if (field && 'label' in field) {
                    return field['label'];
                }
            }
        }

        return this.formatEvidenceTableLabel(fieldName);
    }

    getEvidenceTable(review) {
        const ignoreColumns = ['action', 'id', 'type', 'subtype'];
        const reviewType = 'subtype' in review ? review.subtype : review.type;
        return Object.keys(review)
            .filter(column => !ignoreColumns.includes(column))
            .map(column => {
                const value = review[column];
                const columnLabel = this.getEvidenceTableLabel(column, reviewType);
                return [columnLabel, value];
            });
    }

    handleOpenChangePICOForm(payload) {
        let Action = {
            title: 'changePICO',
            label: 'Change PICO',
            type: 'changePico',
            fields: [{
                        value: payload.data[0].id,
                        name: 'reviewId',
                        field: 'reviewId',
                        type: 'hidden'
                    },
                    {
                        value: payload.paragraphId,
                        field: 'paragraphId',
                        name: 'paragraphId',
                        type: 'number'
                    }],
            optional_fields: [],
            startOpen: false,
            moveToNextArticle: false
        }
        this.handleOpenForm(Action);
        return false;
    }

    handleOpenEditForm(reviews, forceType = '') {
        const review = Array.isArray(reviews) ? reviews[0] : reviews;

        if ('subtype' in review) {
            review.type = review.subtype;
        }

        const type = forceType === '' ? review.type : forceType;
        const { id } = review;
        const action = this.getActionByTitle(type);
        if (!action) {
            return false;
        }

        const { fields } = action;
        const newFields = fields.map(el => {
            if (el.field in review) {
                return { ...el, value: review[el.field] };
            }
            return el;
        });
        const newAction = {
            ...action,
            fields: [
                ...newFields,
                {
                    value: id,
                    name: 'reviewId',
                    type: 'hidden'
                }
            ]
        };
        this.handleOpenForm(newAction);
        return false;
    }

    getActionByTitle(title: string) {
        const action = this.literature.actions.find(act => act.title === title);
        if (action) {
            return action;
        }
        const isInEvidenceAction =
            'evidenceAction' in this.literature && this.literature.evidenceAction.find(act => act.title === title);
        if (isInEvidenceAction) {
            return isInEvidenceAction;
        }
        return false;
    }

    handleOpenForm(action) {
        this.reviewBoxOpen = true;
        const { title, fields, optional_fields = [] } = action;
        let configs = [];

        setTimeout(() => {
            const el = document.getElementById('review_anchor');
            if (el) {
                el.scrollIntoView({ block: 'center' });
            }
        });

        const submitOnChange = event => {
            // if (fields.length === 1) {
            //     this.form.handleSubmit(event);
            // }
        };
        let pageCount = 0;
        fields.forEach(field => {
            const pageNumber = field.page ? field.page : 0;
            pageCount = Math.max(pageCount, pageNumber);
            switch (field.type) {
                case 'hidden':
                    const hiddenConfig = {
                        type: 'hidden',
                        name: field.name,
                        questionId: field.questionId,
                        value: field.value,
                        validation: ''
                    };
                    configs = [...configs, hiddenConfig];
                    break;
                case 'boolean':
                case 'vote':
                    const { votes = [] } = this.literature;
                    const voteField = votes.find(vote => vote.type === field.field);
                    const voteConfig = {
                        type: 'select',
                        label: field.label ? field.label : field.field.replace(this.regex, ' '),
                        name: field.field,
                        questionId: field.questionId,
                        info: voteField && voteField.status ? voteField.status : '',
                        options: field.options,
                        page: field.page ? field.page : 0,
                        placeholder: field.placeholder ?? 'Select an option',
                        validation: optional_fields.includes(field.field) ? '' : [Validators.required],
                        value: voteField ? voteField.vote : '',
                        ...(action.startOpen && { submitOnChange })
                    };

                    configs = [...configs, voteConfig];
                    break;
                case 'select':
                    const selectConfig = {
                        type: 'select',
                        label: field.label ? field.label : field.field.replace(this.regex, ' '),
                        name: field.field,
                        info: field.info ? field.info : '',
                        questionId: field.questionId,
                        options: field.options,
                        page: field.page ? field.page : 0,
                        placeholder: field.placeholder ?? 'Select an option',
                        validation: optional_fields.includes(field.field) ? '' : [Validators.required],
                        value: 'value' in field ? field.value : '',
                        ...(action.startOpen && { submitOnChange })
                    };
                    configs = [...configs, selectConfig];
                    break;
                case 'number':
                    switch (field.field) {
                        case 'paragraphId':
                            const numberConfig = {
                                type: 'paragraphId',
                                label: 'Select the PICO for which the evidence is relevant for:',
                                name: field.field,
                                questionId: field.questionId,
                                info: field.info ? field.info : '',
                                options: this.paragraphs,
                                value: '',
                                page: field.page ? field.page : 0,
                                placeholder: field.placeholder ?? field.type
                                //validation: [Validators.required]
                            };
                            configs = [...configs, numberConfig];
                            break;
                        case 'populationId':
                            const populationIdConfig = {
                                type: 'populationId',
                                label: 'Select the relevant clinical question / PICO:',
                                name: field.field,
                                questionId: field.questionId,
                                info: field.info ? field.info : '',
                                options: this.populations,
                                value: '',
                                page: field.page ? field.page : 0,
                                placeholder: field.placeholder ?? field.type
                            };
                            configs = [...configs, populationIdConfig];
                            break;
                        default:
                            const breakConfig = {
                                type: 'input',
                                label: field.label ? field.label : field.field.replace(this.regex, ' '),
                                name: field.field,
                                questionId: field.questionId,
                                info: field.info ? field.info : '',
                                placeholder: field.placeholder ?? field.type,
                                page: field.page ? field.page : 0,
                                validation: optional_fields.includes(field.field) ? '' : [Validators.required],
                                value: 'value' in field ? field.value : ''
                            };
                            configs = [...configs, breakConfig];
                            break;
                    }
                    break;
                case 'textarea':
                    const textareaConfig = {
                        type: 'editor',
                        label: field.label ? field.label : field.field.replace(this.regex, ' '),
                        name: field.field,
                        questionId: field.questionId,
                        info: field.info ? field.info : '',
                        placeholder: field.placeholder ?? field.type,
                        page: field.page ? field.page : 0,
                        validation: optional_fields.includes(field.field) ? '' : [Validators.required],
                        value: 'value' in field ? field.value : field.placeholder
                    };
                    configs = [...configs, textareaConfig];

                    break;
                case 'text':
                    const textConfig = {
                        type: 'input',
                        label: field.label ? field.label : field.field.replace(this.regex, ' '),
                        name: field.field,
                        questionId: field.questionId,
                        info: field.info ? field.info : '',
                        placeholder: field.placeholder ?? field.type,
                        page: field.page ? field.page : 0,
                        validation: optional_fields.includes(field.field) ? '' : [Validators.required],
                        value: 'value' in field ? field.value : ''
                    };
                    configs = [...configs, textConfig];
                    break;
                default:
                    const defaultConfig = {
                        type: 'input',
                        label: field.label ? field.label : field.field.replace(this.regex, ' '),
                        name: field.field,
                        questionId: field.questionId,
                        info: field.info ? field.info : '',
                        placeholder: field.placeholder ?? field.type,
                        page: field.page ? field.page : 0,
                        validation: optional_fields.includes(field.field) ? '' : [Validators.required],
                        value: 'value' in field ? field.value : ''
                    };
                    configs = [...configs, defaultConfig];
                    break;
            }
        });
        configs = [
            ...configs,
            { value: title, name: 'action', type: 'hidden' },
            { value: action.type === 'vote', name: 'isVote', type: 'hidden' },
            { value: action.moveToNextArticle, name: 'moveToNextArticle', type: 'hidden' },
            ...(!action.hideSubmit && action.startOpen && [
                {
                    label: 'Submit',
                    name: 'submit',
                    type: 'button',
                }
            ]),
            ...(!action.startOpen && [
                {
                    label: 'Submit',
                    name: 'submit',
                    type: 'button',
                    page: 0,
                    handleCancel: () => {
                        this.config = [];
                    }
                }
            ])
        ];
        this.config = configs;
        this.form.wizardStyle = false;
        if (action.type && action.type === 'review' && pageCount > 0) {
            this.form.wizardStyle = true;
        }
        this.form.ngOnInit();
        this.form.setDisabled('submit', true);
        this.form.changes.subscribe(() => {
            this.form.setDisabled('submit', !this.form.valid);
        });
    }

    getSummaryOfFinding(finding) {
        const ignoreColumns = [
            'action',
            'id',
            'user',
            'user_name',
            'status',
            'actionLabel',
            'sourceId',
            'protocol_paragraph',
            'article'
        ];

        return Object.keys(finding)
            .filter(key => !ignoreColumns.includes(key))
            .filter(key => finding[key] !== null)
            .filter(key => finding[key].toString() !== '')
            .map(key => {
                const value = finding[key].toString().replace('illegal reference: ', ''); //todo: fix this in backend
                return {
                    key,
                    value
                };
            });
    }

    showSecondCol() {
        if (this.literature) {
            if (this.literature.actions && this.literature.actions.length) {return true}
            else if (this.literature.findings && this.literature.findings.rowData) {return true}
            else if (this.literature.findings_to_include && this.literature.findings_to_include.rowData) {return true}
        }
        return false
    }

    onUploadFullText() {
        const modalRef = this.modalService.open(UploadFileModalComponent, { size: 'lg' });
        modalRef.componentInstance.eventBus.subscribe(eventAction => {
            this.eventBus.emit({
                type: "onUploadFullText",
                payload: {
                    ...eventAction.payload,
                    protocolId: this.protocolId,
                    articleId: this.literatureId
                }
            });
            modalRef.close();
        });
        modalRef.result.then(
            () => {}, // on close with result
            () => {} // on dismiss with reason
        );
    }

    canViewFullText() {
        return this.userService.isAliiUser();
    }
    onClickAddFile() {
        const modalRef = this.modalService.open(UploadFileModalComponent, { size: 'lg' });
        
        modalRef.componentInstance.files = this.literature.files;
        modalRef.componentInstance.eventBus.subscribe(eventAction => {
            this.eventBus.emit({
                ...eventAction, // handleUploadFile
                payload: {
                    ...eventAction.payload,
                    protocolId: this.protocolId,
                    articleId: this.literatureId
                }
            });
            modalRef.close();
        });
        modalRef.result.then(
            () => {}, // on close with result
            () => {} // on dismiss with reason
        );
    }

    onClickStepHeader(stepId) {
        const payload = { type: 'handleGoToStepFirstArticle', payload: { stepId } };
        this.eventBus.emit(payload);
    }

    trackByFn = (index, item) => item.id || index;

    orderReviews(reviews) {
        if(reviews?.length) {
            reviews = reviews.slice().sort((a, b) => a.type > b.type && 1 || -1)
            return reviews
        }
    }

    openInclusionCriteriaModal() {
        const modalRef = this.modalService.open(ModalInclusionCriteriaComponent);
        modalRef.componentInstance.inclusionCriteria = this.literature.inclusionCriteria;
    }
}
