import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Protocol } from '@alii-web/models/protocol.interface';
import { User } from '@alii-web/models/user.interface';
import { Literatures } from '@alii-web/models/literature.interface';

import { ViewArticleModalComponent } from '../protocol-edit/paragraph-edit/view-article-modal/view-article-modal.component';
import { UserFeedbackModalComponent } from './user-feedback-modal/user-feedback-modal.component';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { setupScrollNavigationLinks } from '../libs';
import { SavePatientLinkModalComponent } from './save-patient-link-modal/save-patient-link-modal.component';


const cn = 'ProtocolDetailComponent';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-protocol-detail',
    templateUrl: './protocol-detail.component.html',
    styleUrls: ['./protocol-detail.component.scss']
})
export class ProtocolDetailComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input()
    channelMessages: any;

    @Input()
    pane: string;

    @Input()
    loading = true;

    @Input()
    gradeAssessment: any;

    @Input()
    loadingModel = false;

    @Input()
    protocol: Protocol;

    @Input()
    isFlowchart: boolean;

    @Input()
    currentVersion: any;

    @Input()
    draftVersion: any;

    @Input()
    deprecatedVersions: any[] = [];

    @Input()
    storedDraftVersions: any[] = [];

    @Input()
    literatures: Literatures;

    @Input()
    users: User[];

    @Input()
    isEditAble?: boolean;

    @Input()
    paragraphSelectedId?: string;

    @Input()
    versionId?: string;

    @Input()
    version?: string;

    @Input()
    modelMessages?: any;

    @Input()
    paragraphsFiles: any;

    @Input()
    modelFindings: any;

    @Input()
    modelOutcomes: any;
    
    @Input()
    modelTagList: any;

    @Input()
    protocolArticle: any;

    @Input()
    viewByPopulation: boolean;

    @Input()
    populations: any;

    @Input()
    views: string[];

    @Input()
    currentView: string;

    @Input()
    populationId: any;

    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    showBanner = true;

    subscriptions: Subscription[] = [];

    showMessageIsLoading = true;

    sidebarCollapsed: boolean;

    // --- Scroll highlight navigation links --- //
    @ViewChild('scroll') scrollRef: ElementRef;
    scrollListener: () => void;
    scrollParagraphId: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private renderer: Renderer2,
        private modalService: NgbModal,
        private titleService: Title,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
    }

    ngAfterViewInit() {
        this._setupInternalLinks();
        this.subscriptions.push(
            this.activatedRoute.queryParams.subscribe(qparams => {
                const { ppdId, fileId } = qparams;
                if (ppdId && fileId) {
                    // Don't forget to switch to text view.
                    this.handleSwitchView('text');
                    const idToggle = 'para-' + ppdId + '-file-toggle';
                    const idFile = 'para-' + ppdId + '-file-' + fileId;
                    let elToggleClicked = false;
                    // Attempt to access toggle element every 200ms until either it is found or max time has been
                    // reached, then attempt to access file element every 200ms until either it is found or max
                    // time has been reached.
                    const TIMEOUT = 200;
                    const MAX = 5000;
                    let msecs = 0;
                    // TODO: Replace with RXJS timer take until which is more elegant.
                    const interval = setInterval(() => {
                        if (!elToggleClicked) {
                            // Look for toggle element
                            const elToggle = document.getElementById(idToggle);
                            if (elToggle) {
                                // Found, click it and restart timer.
                                elToggle.click();
                                elToggleClicked = true;
                                msecs = 0;
                            } else {
                                // Not found
                                msecs += TIMEOUT;
                                if (msecs >= MAX) {
                                    console.warn(`Cannot find toggle element with id='${idToggle}'`);
                                    clearInterval(interval);
                                }
                            }
                        } else {
                            // Look for file element
                            const elFile = document.getElementById(idFile);
                            if (elFile) {
                                // Found, scroll into view.
                                elFile.scrollIntoView({ block: 'center' });
                                this.renderer.addClass(elFile, 'highlight-fade');
                                clearInterval(interval);
                            } else {
                                // Not found
                                msecs += TIMEOUT;
                                if (msecs >= MAX) {
                                    console.warn(`Cannot find file element with id='${idFile}'`);
                                    clearInterval(interval);
                                }
                            }
                        }
                    }, TIMEOUT);
                }
            })
        );

        if (this.protocol.isLiterature || this.protocol.externalUrl)
        {
            this.pane = "settings"
        }
    }


    ngOnChanges(changes: SimpleChanges): void {
        const { protocolArticle, currentView, loading, protocol } = changes;

        if (protocol && protocol.currentValue && protocol.firstChange) {
            this._setTitle(protocol.currentValue.title);
            // If we have reached this page via an url fragment with the anchor id, select the anchor with that value
            // and if it exists scroll it into view.
            const fragment = this.activatedRoute.snapshot.fragment;
            if (fragment) {
                // Don't forget to switch to text view.
                this.handleSwitchView('text');
                this._scrollToAnchor(fragment);
                const action = {
                    type: 'handleStoreEvent',
                    payload: {
                        fragment
                    }
                };
                this.eventBus.emit(action);
            }
        }
        setTimeout(() => this._setupScrollListener(), 200);

        if (protocolArticle && !protocolArticle.firstChange) {
            setTimeout(() => {
                this.modalService.dismissAll();
                this.handleOpenProtocolArticleModal();
            });
        }

        if (
            (currentView && currentView.currentValue === 'text' && currentView.previousValue === 'flowchart') ||
            (loading && !loading.currentValue && loading.previousValue)
        ) {
            // Page has been loaded or Switching back to text view.
            setTimeout(() => this._setupScrollListener(), 200);
        }
    }



    ngOnDestroy() {
        if (this.scrollListener) {
            this.scrollListener();
        }
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }

    patientAvailable() {
        return window.sessionStorage && !!sessionStorage.getItem('populationId');
    }

    handleOpenFeedbackForm() {
        const modalRef = this.modalService.open(UserFeedbackModalComponent, {
            size: 'l' as 'lg'
        });
        modalRef.componentInstance.protocolId = this.protocol.id;
        return false;
    }

    handleOpenSavePatientLinkModal() {
        const modalRef = this.modalService.open(SavePatientLinkModalComponent, {
            size: 'l' as 'lg'
        });
        modalRef.componentInstance.protocolId = this.protocol.id;
        modalRef.componentInstance.patientId = this.populationId;

        return false;

    }

    handleOpenProtocolArticleModal() {
        const modalRef = this.modalService.open(ViewArticleModalComponent, {
            size: 'xl' as 'lg'
        });
        modalRef.componentInstance.article = this.protocolArticle;
    }

    handleEventBus(event) {
        const { type, payload } = event;
        switch (type) {
            case 'handleOpenByVersion':
                this.eventBus.emit(event);
                break;
            case 'handleOpenCompareVersion':
                this.eventBus.emit(event);
                break;
            case 'handleOpenPane':
                this.pane = event.payload.name;
                this.eventBus.emit(event);
                break;

            case 'onUpdateModel':
                this.showMessageIsLoading = payload.showMessageIsLoading;
                delete payload.showMessageIsLoading;
                this.eventBus.emit({
                    ...event,
                    payload: {
                        ...payload,
                        populationId: this.populationId
                    }
                });
                break;


            case 'handleShowOutcomesByPopulationId':
                this.eventBus.emit({
                    ...event,
                    payload: {
                        ...payload,
                        populationId: this.populationId
                    }
                });
                break;

            case 'onSelectOutcome':
                this.eventBus.emit({
                    ...event,
                    payload: {
                        ...payload,
                        populationId: this.populationId
                    }
                });
                break;

            case 'handleAddComment':
                this.eventBus.emit(event);
                break;

            case 'handleClickIncludeSource' || 'handleClickExcludeSource':
                this.eventBus.emit({
                    ...event,
                    payload: {
                        ...payload,
                        protocolId: this.protocol.id
                    }
                });
                break;

            case 'handleCreateLocalVersion':
                this.eventBus.emit({
                    ...event,
                    payload: {
                        ...payload,
                        parentId: this.protocol.id
                    }
                });
                break;

            case 'handleToggleColapse':
                this.sidebarCollapsed = event.payload.isColapsed;
                this.eventBus.emit(event);
                break;

            default:
                this.eventBus.emit(event);
                break;
        }
    }


    handleSwitchView(type) {
        const action = { type: 'handleSwitchView', payload: type };
        this.eventBus.emit(action);
    }

    closeBanner() {
        this.showBanner = false;
    }

    private _setTitle(title: string) {
        this.titleService.setTitle('Alii - ' + title + ' - ' + this.version);
    }

    private _scrollToAnchor(anchorId: string) {
        // Attempt to access anchor element every 200ms until either it is found or max time has been reached.
        const TIMEOUT = 200;
        const MAX = 5000;
        let msecs = 0;
        // TODO: Replace with RXJS timer take until which is more elegant.
        const timerId = setInterval(() => {
            const el = document.getElementById(anchorId);
            if (el) {
                // Found, scroll to anchor into view.
                // IMPORTANT: You MUST use setTimeout here otherwise it will not work.
                setTimeout(() => el.scrollIntoView({ behavior: 'smooth' }), 1000);
                clearInterval(timerId);
            } else {
                // Not found.
                msecs += TIMEOUT;
                if (msecs >= MAX) {
                    console.warn(`${cn} Cannot find fragment='${anchorId}'`);
                    clearInterval(timerId);
                }
            }
        }, TIMEOUT);
    }


    // Turns all links to #paragraph-{id} into links to use a scroll animation to a paragraph on the same page
    private _setupInternalLinks() {
        const links = document.querySelectorAll('div.user-text a');

        const scroller = this._scrollToAnchor;
        setTimeout(() => {
            for (let i = 0; i < links.length; i++) {
                const link = links[i];
                const linkLocation = link.getAttribute("href");
                if (linkLocation.indexOf("//") !== -1) {
                    continue
                }
                else if (linkLocation.indexOf("#paragraph-") !== -1) {
                    link.setAttribute("href", window.location.href.split("#")[0] + linkLocation);
                    link.addEventListener('click', function (event) {
                        scroller(linkLocation.substring(1));
                        event.preventDefault();
                    });
                }
            }
        });
    }

    private _setupScrollListener() {
        if (this.scrollListener) {
            this.scrollListener();
        }
        this.scrollListener = setupScrollNavigationLinks(this.scrollRef, this.renderer, paragraphId => {
            this.scrollParagraphId = paragraphId;
            this.cdr.markForCheck();
        });
    }

    trackByFn = (index, item) => item.id || index;
}
