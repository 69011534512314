import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromStore from './../../store';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'aliiweeb-literatures-detail',
    template: `
        <literatures-detail
            [protocol]="protocol$ | async"
            [protocolId]="protocolId"
            [literatureId]="literatureId"
            [ids]="selectIds$ | async"
            [steps]="steps$ | async"
            [literature]="literature$ | async"
            [versions]="versions$ | async"
            [paragraphs]="paragraphs$ | async"
            [populations]="populations$ | async"
            [loading]="loading$ | async"
            [uploadEvent]="uploadEvent$ | async"
            (eventBus)="handleEventBus($event)"
        ></literatures-detail>
    `
})
export class LiteraturesDetailComponent implements OnInit {
    steps$: Observable<any[]> = this.store.select(fromStore.getSteps);
    literature$: Observable<any> = this.store.select(fromStore.getSelectedLiterature);
    selectIds$: Observable<any> = this.store.select(fromStore.getIds);
    versions$: Observable<any> = this.store.select(fromStore.getVersions);
    paragraphs$: Observable<any[]> = this.store.select(fromStore.getParagraphs);
    populations$: Observable<any[]> = this.store.select(fromStore.getPopulations);
    loading$: Observable<boolean> = this.store.select(fromStore.getLiteraturesCollectionLoading);

    uploadEvent$: Observable<any> = this.store.select(fromStore.getUploadEvent);

    protocol$: Observable<any> = this.store.select(fromStore.SelectProtocol);

    protocolId: string;
    literatureId: string;

    constructor(
        private router: Router,
        private store: Store<fromStore.LiteraturesFeatureState>,
        private route: ActivatedRoute
    ) {
        this.route.paramMap.subscribe(params => {
            this.protocolId = params.get('protocolId');
            this.literatureId = params.get('literatureId');
        });
        this.router.routeReuseStrategy.shouldReuseRoute = function() {
            return false;
        };
    }

    ngOnInit() {
        this.store.dispatch(
            new fromStore.SelectLiterature({ protocolId: this.protocolId, literatureId: this.literatureId })
        );
        
    }
    handleEventBus(event) {
        const { type, payload } = event;
        switch (type) {
            case 'handleLiteratureSubmitAction':
                this.handleLiteratureSubmitAction(payload);
                break;
            case 'handleUploadFile':
                this.handleUploadFile(payload);
                break;
            case 'onUploadFullText':
                this.handleUploadToWebApi(payload);
                break;
            case 'handleGoToStepFirstArticle':
                this.handleGoToStepFirstArticle(payload);
                break;
            case 'handleDeleteRows':
                this.handleDeleteRows(payload);
                break;
            case 'handleCopyRows':
                this.handleCopyRows(payload);
                break;
            case 'createDraftForFinding':
                this.handleCreateDraftForFinding(payload);
            case 'handleGetArticle':
                this.handleGetArticle(payload);
                break;
            default:
                break;
        }
    }
    handleDeleteRows(payload) {
        let findingIds = []
        payload.data.forEach(element => {
            findingIds.push(element.id)
        });
        this.store.dispatch(
            new fromStore.SubmitLiteratureAction({
                findingIds,
                articleId: this.literatureId,
                protocolId: this.protocolId,
                action: 'deleteRow'
            })
        );
    }

    handleCreateDraftForFinding(payload) {
        let findingIds = []
        payload.data.forEach(element => {
            findingIds.push(element.id)
        });
        this.store.dispatch(
            new fromStore.SubmitLiteratureAction({
                findingIds,
                articleId: this.literatureId,
                protocolId: this.protocolId,
                action: 'createDraftForFinding'
            })
        );
    }

    handleCopyRows(payload) {
        let findingIds = []
        payload.data.forEach(element => {
            findingIds.push(element.id)
        });
        this.store.dispatch(
            new fromStore.SubmitLiteratureAction({
                findingIds,
                articleId: this.literatureId,
                protocolId: this.protocolId,
                action: 'copyRow'
            })
        );
    }


    handleLiteratureSubmitAction(payload) {
        const { nextArticleId, isVote, values , action, reviewId} = payload;
        const newPayload = { values, articleId: this.literatureId, protocolId: this.protocolId, reviewId, nextArticleId, isVote, action };
        this.store.dispatch(new fromStore.SubmitLiteratureAction(newPayload));
    }
    handleUploadFile(payload) {
        const { formData, protocolId, articleId, title } = payload;
        this.store.dispatch(new fromStore.UploadFileToBucket({ formData, protocolId, articleId, title }));
    } 
    
    handleUploadToWebApi(payload) {
        const { formData, protocolId, articleId, title } = payload;
        this.store.dispatch(new fromStore.UploadFileToWebApi({ formData, protocolId, articleId, title }));
    }
    handleGoToStepFirstArticle(payload) {
        const { stepId } = payload;
        this.store.dispatch(new fromStore.GoToStepFirstArticle({ protocolId: this.protocolId, stepId }));
    }

    handleGetArticle(payload) {
        const { literatureId, protocolId} = payload;
        this.store.dispatch(new fromStore.GetLiterature({protocolId, literatureId}));
    }
}
